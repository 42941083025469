

:root {
  /* Colors */
  --black: #09090c;
  --grey: #a4b2bc;
  --white: #fff;
  --background: rgba(137, 171, 245, 0.37);
}

html {
  z-index: 1000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  font-family: "Poppins", sans-serif;
}


