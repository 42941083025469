.collapse-css-transition {
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  > * {
    transition-duration: 300ms;
  }
}



.block {
  margin-bottom: 10px;
}

.btn {
  font-size: 100%;
  box-shadow: none;
  width: 100%;
  margin: 0;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.toggle{
  display: flex;
  justify-content: space-between;
  color: #0a4022;
}

header {

  margin-bottom: 40px;

  button {
    font-size: 100%;
    background: none;
    padding: 10px;
    
    + button{
      margin-left: 10px;
    }  
  }

}

.content{
  padding: 10px 10px 30px;
}

button{
  transition: color 280ms;
}
button[disabled]{
  color: #0a4022;
}

.icon-down {
  fill: #0a4022;
  transition: transform 280ms cubic-bezier(0, 1, 0, 1);
  &.is-open {
    transform: rotate(.5turn);
  }
}

