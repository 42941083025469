@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../public/fonts/Poppins-SemiBold.ttf");
}


@font-face {
  font-family: "PoppinsBold";
  src: url("../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsReg";
  src: url("../public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Gotham";
  src: url("../public/fonts/GothamNarrowOffice-BoldItalic.otf");
}

@font-face {
    font-family: "Alfa";
    src: url("../public/fonts/AlfaSlabOne-Regular.ttf");
  }
  @font-face {
    font-family: "Gotham-Medium";
    src: url("../public/fonts/Gotham-Medium.otf");
  }
  @font-face {
    font-family: "Title";
    src: url("../public/fonts/Title.ttf");
  }
  @font-face {
    font-family: "RubikBubbles";
    src: url("../public/fonts/RubikBubbles-Regular.ttf");
  }
  @font-face {
    font-family: "IndieFlower";
    src: url("../public/fonts/IndieFlower-Regular.ttf");
  }
  
  @font-face {
    font-family: "Lato-Medium";
    src: url("../public/fonts/Lato-Medium.ttf");
  }
  @font-face {
    font-family: "Lato-Bold";
    src: url("../public/fonts/Lato-Bold.ttf");
  }
  @font-face {
    font-family: "Lato-Regular";
    src: url("../public/fonts/Lato-Regular.ttf");
  }
  
  
.relative {
    position: relative;
}

.error-texts h1 {
    font-size: 133px;
    font-weight: 900;
    margin-bottom: 20px;
}
.error-texts h3{
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
}
.error-texts p{
    font-size: 16px;
    margin-bottom: 20px;
}
.alert.d-block{
    display: block!important;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw; /* Ekranın genişliğinin %50'si */
  height: 50vw; /* Ekranın yüksekliğinin %50'si */
  max-width: 50vh; /* Ekranın yüksekliğinin %50'sini aşmayacak şekilde kare */
  max-height: 50vh; /* Ekranın yüksekliğinin %50'sini aşmayacak şekilde kare */
  background-color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
