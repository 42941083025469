@charset "utf-8";

/*
Theme Name: Quick Munch - FOOD DELIVERY & MULTIVENDOR HTML5 Template.
Author: Metropolitan Themes
Author URL: https://themeforest.net/user/metropolitantheme/portfolio
Version: 1.0.0

%%%%%%% Table of CSS %%%%%%%

1.General code
    1.1 Typography
    1.2 Space margins and padding
    1.3 forms
    1.4 Buttons
    1.5 Section-Colors-&-Backgrounds
    1.6 Modal & popups
2.Homepages
    2.1 Navigation
    2.2 Categories
    2.3 Recent Order
    2.4 Explore Collection
    2.5 Page Banner
    2.6 Advertisement Slider
    2.7 Blog Section
    2.8 Footer
        2.8.1 Footer Top
    2.10 Copyright
3.Blog Grid
4.Ex Deals
5.Restaurent
6.Add Restaurent
7.List View
8.Login & register
9.Checkout
10.Final Order
11.Geo Locator
12.About Us
*/

/*======================
1.General Code
========================*/

.divShopping {

    background:white;
    position:relative;
    margin-top:25px;
  
}
.divShopping:before, .divShopping:after {
    content:'';
    width:100%;
    height:5px;
    position:absolute;
    bottom:100%;
    left:0;
    background-image: linear-gradient(135deg, transparent 66%, white 67%),
                      linear-gradient(45deg, white 33%, gray 34%, transparent 44%);
    background-position: -5px 0;
    background-size: 10px 100%;
    background-repeat:repeat-x;
}
.divShopping:after {
    top:100%;
    bottom:auto;
    background-image: linear-gradient(135deg, white 33%, gray 34%, transparent 44%),
                      linear-gradient(45deg, transparent 66%, white 67%);    
}


html {
    overflow-x: hidden;
}
button:focus,
*:focus {
    outline: none;
}
body {
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    line-height: 1.8;
    font-weight: 400;
    color: #000000;
    background: #ffffff;
    transition: transform ease-in .4s;
    overflow: hidden;
}

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    justify-items: center;
    justify-content: center;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }
}

button {
    background: transparent;
    border: none;
    padding: 0;
}
label {
    line-height: normal;
}
.align-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.p-relative {
    position: relative;
}
.none {
    display: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.overlay-2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.overlay-bg{
    background: #000;
    opacity: 0.5;
}

.transform-center {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0%, -50%);
    z-index: 1;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before {
    font-size: inherit;
    margin: 0;
}
.swiper-button-next,
.swiper-button-prev {
    box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    top: 52%;
    transform: translate(0%, -48%);
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 14px;
    font-weight: 900;
    color: #ff0018;
}
.swiper-button-next {
    right: 10px;
}
.swiper-button-prev {
    left: 10px;
}
.swiper-button-disabled {
    display: none;
}
.back-btn button {
    box-shadow: 0 2px 14px 0px rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    background: #fff;
    font-size: 16px;
    font-weight: 900;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.u-line {
    border-bottom: 1px solid rgba(67, 41, 163, .2);
}
.banner-adv-bg{
    width: 100%;
    height: auto;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 999;
}
.banner-adv {
    position: relative;
    height: 138px;
    background-image: url('../img/banner-adv-bg.png');
    background-color: rgba(255, 0, 24, 0.58);
    background-size: cover;
    background-position: center;
    width: 100%;
    overflow: hidden;
}
.banner-adv .flex-adv{
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.banner-adv .flex-adv .btn-submit{
    color: #fff;
    background: #ff0018;
    border-color: #ff0018;
}
.banner-adv .flex-adv a i{
    font-size: 28px;
    color: #fff;
    margin-right: 10px;
}
.banner-adv .text {
    text-align: center;
    font-size: 24px;
    margin-right: 20px;
    font-weight: 700;
    color: #fff;
    text-decoration: underline;
}
.banner-adv .close-banner{
    cursor: pointer;
    position: absolute;
    right: 22px;
    top: 8%;
    width: 35px;
    height: 35px;
    display: block;
    border-radius: 50%;
    z-index: 9;
    border: 1px solid #fff;
    color: #fff;
}
.banner-adv2 .close-banner {
    cursor: pointer;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    display: block;
    z-index: 9;
    color: #fff;
}
.banner-adv .close-banner:before,
.banner-adv .close-banner:after{
    border-bottom: 2px solid;
    content: "";
    position: absolute;
    left: 9px;
    top: 16px;
    width: 15px;
}
.banner-adv2 .close-banner:before,
.banner-adv2 .close-banner:after{
    border-bottom: 2px solid;
    content: "";
    position: absolute;
    left: 12px;
    top: 19px;
    width: 15px;
}
.banner-adv2 .close-banner:before,
.banner-adv .close-banner:before {
    transform: rotate(-45deg);
}
.banner-adv2 .close-banner:after,
.banner-adv .close-banner:after {
    transform: rotate(45deg);
}
.banner-adv2 .close-banner{
    display: none;
}
.banner-adv2 {
    position: relative;
    height: 100px;
    background: #fff;
    width: 100%;
    overflow: hidden;
}
.banner-adv2>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner-adv2 .text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-adv2 .text img {
    margin: 0 25px;
}
#scrollstyle-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}
#scrollstyle-4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}
#scrollstyle-4::-webkit-scrollbar-thumb {
    background-color: #ff0018;
}
.main-box {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    background: #fff;
}
.section-header{
    margin: 0 auto;
    max-width: 660px;
    padding-bottom: 20px;
    text-align: center;
}
.section-header-left{
    margin: 0;
    text-align: left;
    max-width: 100%;
    padding-bottom: 20px;
}
.section-header-right{
    margin: 0;
    text-align: right;
    max-width: 100%;
    padding-bottom: 20px;
}
.section-header-style-2 .header-title,
.section-header-left .header-title,
.section-header-right .header-title,
.section-header .header-title{
    margin-bottom: 0;
    font-size: 28px;
}
.section-header-style-2{
    margin: 0 auto;
    max-width: 660px;
    padding: 10px;
    text-align: center;
}
.section-header-style-2 .sub-title{
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px;
}
/* Custom Panel */
.custom-builder-panel {
    position: fixed;
    top: 20%;
    z-index: 3333;
    left: -150px;
    transition: 0.5s all;
}
.custom-builder-panel:hover {
    left: 0;
}
.builder-switch {
    position: absolute;
    right: -50px;
    top: 0;
    background-color: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-animation: bounceRight 2s infinite;
    animation: bounceRight 2s infinite;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c9b38c' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

}
.custom-builder-panel:hover .builder-switch{
  animation: bounceRight 0s;
  -webkit-animation: bounceRight 0s;
}
.builder-slide-panel{
    width: 150px;
    height: 50px;
    background-color: #000000;
    padding: 6px 6px;
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c9b38c' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

}
.builder-slide-panel h4{
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center;
  line-height: 2.8;
}
.builder-slide-panel h4{
  font-size: 14px;
  font-weight: bold!important;
  color: #3eff01!important;
  vertical-align: middle;
  text-transform: uppercase;
}
.switch-icon {
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch-icon i{
  vertical-align: middle;
  color: #3eff01;
}
.custom-builder-panel:hover .builder-switch:before{
  animation: box_ripple 3s cubic-bezier(.18,.74,.72,1);
  text-decoration: none;
}
.builder-switch:before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.44);
  opacity: 0;
  transform: scale(1,1) translate(-50%);
  transform-origin: 50% 50%;
  z-index: 1;
  animation: box_ripple 2s cubic-bezier(.18,.74,.72,1) infinite;
  text-decoration: none;
}

.custom-builder-panel:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 5px;
    background: rgba(0, 0, 0, 0.44);
    opacity: 0;
    transform: scale(1,1) translate(-50%);
    transform-origin: 50% 50%;
    z-index: 1;
}
.custom-builder-panel:hover:before{
    animation: box_ripple 1s cubic-bezier(.18,.74,.72,1);
    text-decoration: none;
}
@keyframes box_ripple{
    0%{
        transform:scale(0,0);
        opacity:.3
    }
    40%{
        transform:scale(15,15);
        opacity:.3
    }
    100%{
        opacity:0;
        transform:scale(40,40)
    }
}
/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/*======================
1.1 Typography
========================*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000000de;
}
h1 {
    font-size: 3.5vw;
    line-height: normal;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 28px;
}
h4 {
    font-size: 26px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}
p {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}
a {
    color: #ff0018;
    text-decoration: none;
    transition: 0.5s;
}
a:focus,
a:hover {
    color: #bc2634;
    text-decoration: none;
    transition: 0.5s;
}
dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
ul li,
ol li {
    margin: 0px;
    padding: 0px;
    position: relative;
    list-style: none;
}
blockquote {
    background-color: #f4f4f4;
    position: relative;
    padding: 3rem;
    margin: 2rem 0;
    border: #4329a333 solid 1px;
}
blockquote p {
    font-size: 32px;
    line-height: 45px;
    font-style: italic;
    margin: 0;
    word-break: break-word;
}
blockquote h6 {
    color: #000;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
}
img {
    max-width: 100%;
    width: auto;
    height: auto;
    border-radius: 3px;
}
table th,
table td {
    border: 1px solid #ccc;
    padding: 15px;
}
table {
    border: 1px solid #ccc;
    padding: 15px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fw-100 {
    font-weight: 100;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 900;
}
.title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    font-size: x-large;
}
.title-2 {
    display: flex;
    align-items: baseline;
}
.title-2 small {
    margin-left: 30px;
}
/*pagination*/

.custom-pagination {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-pagination .page-link {
    padding: 10px 20px;
    color: #ff0018;
}
.custom-pagination .page-item .page-link:hover,
.custom-pagination .page-item.active .page-link {
    background: #ff0018;
    color: #fff;
    border-color: #ff0018;
}
/*======================
1.2 Space margins and padding
========================*/

.no-margin {
    margin: 0;
}
.no-padding {
    padding: 0;
}
.full-width {
    width: 100%;
}
.section-padding {
    padding: 0px 0;
    background-color: #f5f5f5;
}
.section-padding img {
  
    width: 100%;
    
}
.padding-10 {
    padding: 10px;
}
.padding-tb-10 {
    padding: 10px 0;
}
.padding-15 {
    padding: 15px;
}
.padding-20 {
    padding: 20px;
}
.main-sec {
    height: 60px;
    clear: both;
    width: 100%;
}
.mb-xl-20 {
    margin-bottom: 20px;
}
.pb-xl-20 {
    padding-bottom: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
/*==================
1.3. Forms
====================*/
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #495057bd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
input[type=text]::placeholder {
    color: #000000de;
    font-size: 14px;
}
.form-control-submit {
    border: 1px solid #8f8fa1;
    border-radius: 4px;
    padding: 10px 16px;
    height: 100%;
}
label.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
label.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;
    line-height: normal;
    font-size: 14px;
}
label.custom-checkbox:last-child {
    margin-bottom: 10px;
}
/* Create a custom checkbox */

label.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #d4d4db;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;
}
/* On mouse-over, add a grey background color */

label.custom-checkbox:hover input ~ .checkmark {
    background-color: #fff;
    border: 1px solid #ff0018;
}
/* When the checkbox is checked, add a blue background */

label.custom-checkbox input:checked ~ .checkmark {
    background-color: #ff0018;
    border: 1px solid #ff0018;
}
/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */

label.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */

label.custom-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
/*==================
1.4. Buttons
====================*/

.btn-first {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 16px 5px;
    font-size: 13px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.white-btn {
    border-color: rgba(67, 41, 163, .2);
    background: #fff;
}
.white-btn:hover,
.white-btn:focus {
    border-color: #ff0018;
}
.green-btn {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.green-btn:hover,
.green-btn:focus {
    border-color: #ff0018;
    background: #fff;
    color: #ff0018;
}
.paypal-btn {
    color: #fff;
    background-color: #ffc439;
    border-color: #ffc439;
}
.paypal-btn:hover,
.paypal-btn:focus {
    border-color: #ffc439;
    background: #ffc439;
    color: #fff;
}
.category-btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: #ffffff94;
    border: 1px solid transparent;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #000;
    font-weight: 600;
}
.category-btn:hover,
.category-btn:focus {
    border-color: #ff0018;
    background: #ff0018;
    color: #fff;
}
.btn-second {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 9px 15px 8px;
    font-size: 14px;
    line-height: 1.51741;
    border-radius: 4px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: 500;
    position: relative;
}
.btn-second img {
    float: left;
    left: -8px;
    position: relative;
    width: 22px;
    height: 22px;
}
.btn-submit {
    color: 0a4022;
    background: #0a4022;
    border-color: #0a4022;
}
.btn-submit:hover,
.btn-submit:focus {
    border-color: #FFC04B;
    background: #FFC04B;
    color: #fff;
}
.btn-facebook {
    color: #fff;
    background: #3b5998;
    border-color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus {
    border-color: #2d4373;
    background: #2d4373;
    color: #fff;
}
.btn-google {
    color: #fff;
    background: #4285f4;
    border-color: #4285f4;
}
.btn-google:focus,
.btn-google:hover {
    border-color: #1266f1;
    background: #1266f1;
    color: #fff;
}
span.circle-tag {
    height: 36px;
    width: 36px;
    background: #fff;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
span.square-tag>img,
span.rectangle-tag>img,
span.circle-tag>img{
    padding: 8px;
}
span.rectangle-tag {
    height: 30px;
    width: auto;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    letter-spacing: 2px;
    padding: 8px;
}
span.rectangle-tag+span.rectangle-tag{
    margin: 0 2px;
}
span.square-tag {
    height: 35px;
    width: 35px;
    background: transparent;
    border-radius: 3px;
    font-size: 16px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.share-tag {
    height: 36px;
    width: 36px;
    background: #fff;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
span.deal-tag {
    border-radius: 3px;
    padding: 4px 10px;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
}
span.add-product {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 1px solid #ff0018;
    background-color: #fff;
    color: #ff0018;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
span.add-product i {
    font-size: 12px;
}
span.type-tag{
    padding: 3px 15px;
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;
}
/*==================
1.5. Section-Colors-&-Backgrounds
====================*/

.bg-light-white {
    background-color: #f4f4f4;
}
.bg-light-theme {
    background-color: #fbfbfc;
}
.bg-light-green {
    background-color: #ff0018;
}
.bg-red{
    background-color: #ff0000;
}
.bg-gradient-red{
    background-image: linear-gradient(to right,#f20000, #a20000);
}
.bg-yellow{
    background-color: #ffc000;
}
.bg-green{
    background-color: #4aa110;
}
.bg-gradient-green{
    background-image: linear-gradient(to bottom,#499a14, #476d2d);
}
.bg-gradient-orange{
    background-image: linear-gradient(to bottom,#c55a11, #ed7d31);
}
.bg-black{
    background-color: #000;
}
/*Colors*/

.text-light-black {
    color: #000000de;
}
.text-orange {
    color: #fb6100;
}
.text-custom-white {
    color: #ffffff;
}
.text-light-white {
    color: #6b6b83;
}
.text-dark-white {
    color: #00000059;
}
.text-yellow {
    color: #fcb71b;
}
.text-light-green {
    color: #ff0018;
}
.text-success {
    color: #13aa37;
}
.text-footer {
    color:#c2c2c2;
}
/*======================
1.6 Modal & Popups
========================*/

#offer .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
#offer.modal {
    height: auto;
    top: 50%;
    left: 0%;
    right: 0;
    transform: translate(0%, -50%);
}
.offer-content {
    text-align: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 20px 10px 10px;
    position: relative;
    min-height: 100%;
    background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8), url('../img/offer.jpg');
    background-size: cover;
}
#offer .modal-header .close {
    position: absolute;
    right: 0;
    padding: 20px;
    margin: 0;
}
.offer-content h2 {
    text-transform: uppercase;
    font-size: 50px;
}
.offer-content h2 small {
    display: block;
    font-size: 30px;
    text-transform: lowercase;
}
#address-box .modal-content,
#search-box .modal-content {
    min-height: 85vh;
    background: #fbfbfc;
}
#address-box .modal-header,
#search-box .modal-header {
    display: block;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
}
#address-box .modal-header .close,
#search-box .modal-header .close {
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    color: #ff0018;
    opacity: 1;
    padding: 0;
    font-size: 35px;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 20px;
}
#address-box .modal-title,
#search-box .modal-title {
    font-size: 20px;
}
#address-box .modal-body,
#search-box .modal-body {
    padding: 1rem 0;
}
/*======================
2. Homepages
========================*/
/*Navigation*/

.notification-dropdown .rating-box {
    padding: 10px;
    text-align: center;
}
.notification-dropdown .rating-box span {
    font-size: 20px;
    cursor: pointer;
}
.notification-dropdown .rating-box cite {
    display: block;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
}
.cart-dropdown .cart-detail-box .card {
    border: none;
}
.cart-dropdown .cart-detail-box .card .card-header,
.cart-dropdown .cart-detail-box .card .card-footer {
    background: #fff;
}
.cart-dropdown .cart-detail-box .card .card-body .item-total .total-price,
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name a p {
    margin-bottom: 0px;
}
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .cat-name a p span {
    margin-right: 10px;
}
.cart-dropdown .cart-detail-box .card .card-body .cat-product-box .cat-product .delete-btn i {
    font-size: 12px;
}
.parent-megamenu>a>i,
.mobile-search {
    display: none;
    padding: 0;
}
.header .mobile-address .delivery-add {
    align-items: center;
    justify-content: center;
    background: #fff;
}
.sorting-addressbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f4f4f4;
    padding: 10px 15px;
}
.sorting-addressbox .btns .filter-btn {
    margin-left: 15px;
}
.sorting-addressbox .btns .filter-btn button {
    display: block;
    text-align: center;
    margin: 0 auto;
}
#search-box .modal-header .search-box {
    display: flex;
    align-items: center;
}
.header .main-search>.row>div {
    align-self: center;
}
#search-box .modal-header .search-box:before {
    content: '\f002';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    padding: 0 8px;
    font-size: 18px;
    color: #6b6b83;
}
#search-box .modal-header .search-box input {
    padding: .375rem 10px .375rem 40px;
    border-radius: 0;
}
/*megamenu style 1*/
.parent-megamenu .megamenu {
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    left: 15%;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    transition: .3s;
    opacity: 0;
    visibility: hidden;

}
.parent-megamenu:hover>.megamenu {
    opacity: 1;
    visibility: visible;
    left: 0;
}
.parent-megamenu .megamenu>div {
    padding: 30px 15px;
    margin-left: 0;
    margin-right: 0;
    background-size: cover;
}
.parent-megamenu .megamenu .menu-style {
    padding-bottom: 40px;
    text-align: center;
}
.parent-megamenu .megamenu .menu-style .menu-title .cat-name {
    padding-bottom: 16px;
    margin-bottom: 11px;
    letter-spacing: .1em;
    text-transform: uppercase;
    position: relative;
}
.parent-megamenu .megamenu .menu-style .menu-title .cat-name:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #ff0018;
    width: 42px;
    right: 0;
    margin: 0 auto;
}
.parent-megamenu .megamenu .menu-style ul li {
    line-height: 36px;
    text-transform: capitalize;
}
.parent-megamenu .megamenu .menu-style ul li.active a{
    color: #ff0018;
}
.parent-megamenu .megamenu .menu-style ul li a {
    position: relative;
    display: inline-block;
    font-size: 14px;
}
/*Categories*/
.browse-cat .categories {
    text-align: center;
    display: block;
    border-width: 3px;
    border-radius: 5px;
}

.browse-cat .categories .icon {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    width: 125px;
    height: 125px;
}
.browse-cat .categories .icon2{
    padding: 0px;
    width: 100%;
    height: 100%;
}
.browse-cat .categories .icon i {
    font-size: 45px;
}
.browse-cat .categories .icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.browse-cat .categories .cat-name {
    padding: 5px 0;
    display: block;
    font-size: 16px;
}
.browse-cat-2 .categories .icon {
    position: relative;
}
.browse-cat-2 .categories .icon img {
    border-radius: 0;
}
.browse-cat-2 .categories .icon .product-addbtn {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.browse-cat-2 .categories .icon .product-addbtn span i {
    font-size: 12px;
}
/*Recent Order*/
.product-box-3,
.product-box {
    position: relative;
    border-radius: 14px;
    margin: 20px;
    background-color: #c5aa77;
}
.swiper-slide .testimonial-wrapper,
.swiper-slide .product-box-3,
.swiper-slide .product-box {
    margin: 4px;
    background-color: #c9c9c9;
}
.product-box-3:hover,.product-box:hover {
      box-shadow: 0 0 0 1px rgba(26, 26, 26, 0.17), 0 1px 5px 0 rgba(20, 20, 20, 0.12);
      background-color: #ffffff;

}
.product-box-3 .product-img,
.product-box .product-img {
    position: relative;
    width: 100%;
}
.product-box-3 .product-caption,
.product-box .product-caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px;
    width: 100%;
}
.product-box .product-caption .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.product-box .product-caption .product-title {
    margin-bottom: 0px;
    font-size: 20px;
    text-align: center;
    width: 100%;
}
.product-box .product-caption p {
    font-size: 12px;
    text-align: center;
}
.product-box .product-caption .product-btn {
    margin-top: 10px;
}
.product-box .product-img .product-tags .custom-tag,
.product-box .product-img .product-tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.product-box .product-img .product-tags>span:first-child {
    position: absolute;
    top: 10px;
    right: 10px;
}
.product-box .product-caption .product-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.product-box .product-caption .product-details span {
    display: block;
    line-height: normal;
}
.product-box .product-caption .product-footer {
    margin-top: 10px;
     display: flex;
    align-items: center;
    justify-content: flex-start;
}
.product-box .product-footer-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fbfbfc;
    padding: 12px 10px;
}
/*featured-product*/
.featured-product {
    position: relative;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    margin: 3px;
}
.featured-product:hover {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .2), 0 1px 5px 0 rgba(67, 41, 163, .2);
}
.featured-product .featured-product-details {
    padding: 15px;
    display: flex;
}
.featured-product .featured-product-details .pro-logo {
    width: 64px;
    height: 64px;
    overflow: hidden;
    flex-shrink: 0;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(66, 41, 163, .08), 0 0 0 1px rgba(66, 41, 163, .08);
    margin-right: 15px;
}
.featured-product .featured-product-details .pro-title {
    max-width: 200px;
}

/*product-box-3*/
.product-box-3 .product-caption ul {
    display: flex;
    flex-wrap: wrap

}
.product-box-3 .product-caption ul.food-type li {
    padding: 0 7px;
    position: relative;
    margin-bottom: 10px;

    
}
.product-box-3 .product-caption ul.food-type li:after {
    content: '';
    background: #6b6b83;
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
    
}
.product-box-3 .product-caption ul.food-type li:first-child {
    padding-left: 0;
}
.product-box-3 .product-caption ul.food-type li:last-child:after {
    display: none;
}
.product-box-3 .product-caption ul.food-description li {
    justify-content: center;
    align-items: center;
}
/*Explore Collection*/
.ex-collection-box {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.ex-collection-box:hover img {
    transform: scale(1.1);
    transition: 0.5s;
}

.ex-collection-box2 {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.ex-collection-box2:hover img {
    transform: scale(1.06);
    transition: 0.5s;
}

.ex-collection .category-type a {
    position: absolute;
    bottom: 0;
    margin-bottom: 15px;
}
.large-product-box {
    position: absolute;
    top: 0;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    padding-bottom: 20px;
    right: 15px;
    left: 15px;
}
.large-product-box img {
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.large-product-box .category-type a {
    left: 15px;
    width: auto;
    right: 15px;
    margin-bottom: 35px;
}
/*Page Banner*/
.banner-1 {
    height: 520px;
    width: 100%;
}
.banner-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.banner-1 .content-wrapper {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
}
.banner-1 .content-wrapper .content-box .input-group {
    margin: 0 -8px;
}
.banner-1 .content-wrapper .content-box .input-group-prepend {
    height: 48px;
    min-width: 44px;
    flex-basis: 44px;
    border: 1px solid #8f8fa1;
    border-left: 0;
    border-radius: 0px 4px 4px 0;
    display: none;
}
.banner-1 .content-wrapper .content-box .input-group-prepend .input-group-text {
    line-height: 1.5;
    font-size: 20px;
    background: transparent;
    border: none;
}
.banner-1 .content-wrapper .content-box .input-group .input-group2 {
    display: flex;
}
.banner-1 .content-wrapper .content-box .input-group .input-group2,
.banner-1 .content-wrapper .content-box .input-group-append {
    padding: 0 8px;
}
.banner-1 .right-side-content h1 {
    font-size: 60px;
    line-height: 1.2;
}
/*Advertisement Slider*/
.advertisement-slider {
    height: 100%;
}
.advertisement-slider .swiper-wrapper .swiper-slide .large-product-box {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
}
/*Blog Section*/
.our-blog .blog-box {
    display: flex;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    background: #fff;
}
.our-blog .blog-box .post {
    flex: 0 0 66.6666%;
    max-width: 66.6666%;
}
.our-blog .blog-box .side-blog {
    flex: 0 0 33.3334%;
    max-width: 33.3334%;
}
.our-blog .blog-box .post .blog-img img,
.our-blog .blog-box .post .blog-img {
    height: 100%;
}
.our-blog .blog-box .post .blog-img .blog-meta {
    bottom: 20px;
    position: absolute;
}
.our-blog .blog-box .side-blog .blog-list .side-post {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.our-blog .blog-box .side-blog .blog-list .side-post:last-child{
    border-bottom:none;
}
.our-blog .blog-box .side-blog .blog-list .side-post .thumb-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 100px;
    max-width: 100px;
}
.our-blog .blog-box .side-blog .blog-list .side-post .content-wrap {
    flex: 0 0 calc(100% - 120px);
    max-width: calc(100% - 120px);
}

/*footer*/
footer {
    border-top: 1px solid rgba(67, 41, 163, .2);
}
footer .ft-logo-box {
    display: flex;
    align-items: center;
}
footer .ft-logo-box .ft-logo {
    margin-right: 25px;
}
footer .ft-social-media {
    display: block;
    align-items: center;
    height: 100%;
    padding-top: 80px;
}
footer .ft-social-media ul {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
footer .ft-social-media ul li {
    margin-left: 15px;
    border:1px solid #ada3a3;
    transition: 0.3s;
}
footer .ft-social-media ul li a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ada3a3;
    transition: 0.3s;
    text-decoration: none;
}
footer .ft-social-media ul li a i{
  font-size: 18px;
}
footer .ft-social-media ul li:first-child{
  margin-left: 0;
}
footer .subscribe_form button{
  position: absolute;
  right: 3px;
  top:3px;
  z-index:1;
}
.copyright .medewithlove{
  overflow: hidden;
}
.copyright .madewithlove a{
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.copyright .medewithlove a:hover i{
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.copyright .medewithlove a i{
  color: red;
}
.hm-list.hm-instagram {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}
.hm-list.hm-instagram li {
    overflow: hidden;
}
.hm-list.hm-instagram li {
    position: relative;
    margin-right: 0;
}
.hm-list li {
    display: inline-block;
    margin-right: 15px;
}
.hm-list.hm-instagram li img {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0px;
}
.hm-list.hm-instagram li:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
footer .subscribe_form input{
  padding: 10px 55px 10px 10px;
}
footer .ft-social-media ul li:hover{
    border-color: #ff0018;
    transform: translateY(-5px);
    transition: 0.3s;
}
footer .ft-social-media ul li:hover a{
    color: #ff0018;
    transform: rotate(25deg);
    transition: 0.3s;
}
footer .ft-logo-box{
    padding-bottom: 80px;
}
footer .footer-links,
footer .footer-contact {
    padding-top: 80px;
}
footer .footer-links ul,
footer .footer-contact ul {
    margin-bottom: 10px;
}
footer .footer-contact li {
    margin-bottom: 10px;
}
footer .footer-contact li a {
    text-decoration: underline;
}
footer .footer-links ul li {
    margin-bottom: 5px;
}
footer .footer-links .appimg {
    margin-bottom: 10px;
}
/*footer-top*/
.footer-top .icon-box {
    margin: 0 auto;
    text-align: center;
    max-width: 150px;
    word-break: break-all;
}
.footer-top .icon-box span i {
    font-size: 50px;
    margin-bottom: 10px;
}
.footer-top .icon-box span {
    display: block;
    font-size: 12px;
}
/*copyright*/
.copyright {
    padding: 20px 0;
}
.copyright .payment-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.copyright .copyright-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
/*======================
3. Blog Grid
========================*/
.breadcrumb .breadcrumb-item {
    margin: 0;
}
.breadcrumb {
    margin: -20px;
    background-color: #fff;
    padding: 1.5rem 20px;
    border-bottom: 1px solid #4329a333;
    border-radius: 0;
    margin-bottom: 20px;
}
.breadcrumb .breadcrumb-item a {
    color: #131313;
    font-weight: 600;
}
.breadcrumb-item.active {
    color: #FFC04B;
}
.our-articles .review-img {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin-bottom: 10px;
}

.our-articles .review-img img {
    margin-bottom: 30px;
}


.our-articles .blog-page-banner {
    position: relative;
    background-image: url('../img/banner/grinder-wooden-spoon-with-spices.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(280px * 2);
    background-attachment: fixed;
}
.our-articles .blog-page-banner:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .6), transparent);
}
.our-articles .blog-inner {
    position: relative;
    margin-top: -450px;
    display: flex;
}
.side-blog .side-post {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.side-bar .trending-blog-cat li:last-child,
.side-blog .side-post:last-child{
    margin-bottom: 0;
    padding-bottom: 0;;
    border-bottom: none;
}
.side-blog .content-wrap {
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
}
.side-blog .thumb-img {
    position: relative;
    overflow: hidden;
    flex: 0 0 100px;
    max-width: 100px;
    margin-right: 15px;
}
.side-bar .blog-tags a {
    display: inline-block;
    padding: 5px 10px;
    border: #4329a333 solid 1px;
    margin: 0 5px 10px 0;
    background: #f5f5f5;
    font-weight: 500;
    color: #000000de;
}
.side-bar .trending-blog-cat li {
    display: flex;
    position: relative;
}
.side-bar .trending-blog-cat li a {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    text-transform: capitalize;
    justify-content: space-between;
}
.side-bar .large-product-box {
    position: relative;
    height: auto;
    padding-bottom: 0;
    right: 0;
    left: 0;
}
.side-bar .large-product-box .category-type a {
    bottom: 0;
    position: absolute;
    margin-bottom: 15px;
}
.side-bar .main-padding {
    padding: 15px;
}
.side-bar .inner-wrapper{
    z-index: 1;
}
.side-bar .main-banner,
.side-bar .section-2 {
    height: auto;
}
.side-bar .main-padding img {
    width: 110px;
}
.side-bar .main-banner .footer-img {
    max-width: 200px;
}
.side-bar .inner-wrapper .main-page .login-box {
    display: block;
}
.our-articles .blog-services-wrapper {
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}
.our-articles .blog-services-wrapper .post-meta {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
}
.our-articles .blog-services-wrapper .author-meta {
    margin-left: 20px;
}
/*======================
4. Ex Deals
========================*/
.exclusive-deals .deals-heading {
    padding: 15px 0;
}
.exclusive-deals .deals-image {
    display: flex;
    align-self: center;
    align-items: center;
}
/*product-box-2*/
.product-box-2 {
    display: flex;
}
.product-box-2 .product-img {
    width: 100%;
    height: 202px;
}
.product-box-2 .product-img img {
    width: 100%;
    height: 100%;
    object-fit:contain;
    object-position: center;
}
.local-deals .btn-first{
    margin: 20px auto 40px;
}
/*======================
5. Restaurent
========================*/
.page-banner {
    height: 270px;
}
.page-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.page-banner>.overlay-2>.container>.row {
    padding-top: 10px;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.tag-share {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
/*Restaurent Details*/

.heading .title {
    margin-bottom: 5px;
}
.heading .sub-title span {
    margin-left: 20px;
}
.restaurent-logo {
    position: absolute;
    top: -40%;
    right: 15px;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    background: #fff;
    width: 80px;
    height: 80px;
}
.heading .head-rating .rating span.rate-data {
    margin-left: 5px;
    vertical-align: middle;
    position: relative;
    top: -1px;
}
.heading .head-rating,
.heading .head-rating .product-review {
    display: flex;
    align-items: center;
}
.heading .head-rating .product-review {
    margin-left: 35px;
}
.heading .head-rating .product-review h6 {
    margin-right: 25px;
}
.heading .head-rating .product-review h6 span {
    font-weight: 400;
}
.restaurent-details-mob {
    display: none;
    text-align: center;
}
.restaurent-details-mob span {
    display: block;
    font-weight: 600;
}
/*Restaurent tabs*/

.restaurent-tabs.fix-tab {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 2;
}
.restaurent-tabs .restaurent-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.restaurent-tabs .nav-pills .nav-link {
    margin-right: 15px;
    margin-bottom: 0;
    padding: 10px 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
}
.restaurent-tabs .nav-pills .nav-link.active {
    border-bottom: 3px solid #ff0018;
    color: #000;
    opacity: 1;
    background-color: transparent;
}
.restaurent-tabs .restaurent-menu .add-wishlist img{
    width: 20px;
    cursor: pointer;
}
/*promocode*/

.promocodeimg {
    height: 115px;
}
.promocodeimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.promocode-text {
    position: absolute;
    top: 0;
    display: flex;
    right: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    flex-wrap: wrap;
}
.promocode-text .promocode-text-content h5,
.promocode-text .promocode-text-content p {
    word-break: break-all;
}
.promocode-btn a {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 3px;
    padding: 12px 15px;
    font-size: 16px;
    font-weight: 600;
}
/*Restaurent Address*/

.restaurent-address {
    padding: 15px 0;
}
.restaurent-address .address-details .address,
.restaurent-address .address-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.restaurent-address .address-details .address {
    flex-basis: auto;
    flex-grow: 0;
    max-width: 100%;
}
.restaurent-address .address-details .address .delivery-address {
    padding-right: 40px;
}
.restaurent-address .address-details .change-address {
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 15px;
    text-align: right;
    justify-content: center;
}
/*restaurent-ad*/
.restaurent-ad .ad-img {
    height: 350px;
}
.restaurent-ad .ad-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.restaurent-ad .ad-img .content-box {
    padding: 0 60px;
}
/*Restaurent Meals*/
.restaurent-meals .restaurent-meal-head>.card {
    padding: 0;
    margin-bottom: 25px;
    border: none;
    background: transparent;
}
.restaurent-meals .restaurent-meal-head>.card>.card-header {
    border: none;
    padding: .75rem 0 0;
    background: transparent;
}
.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    font-size: 25px;
    cursor: pointer;
}
.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link[aria-expanded="false"]:after,
.restaurent-meals .restaurent-meal-head>.card>.card-header>.section-header-left>h3>.card-link.collapsed:after {
    content: '\f078';
}
.restaurent-meals .restaurent-meal-head>.card>.collapse>.card-body {
    padding: 0;
}
.restaurent-product-list {
    position: relative;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
    height: 95%;
}
.restaurent-product-list .restaurent-product-detail {
    display: flex;
    justify-content: space-between;
    height: 100%;
}
.restaurent-product-list:hover {
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .2), 0 1px 5px 0 rgba(67, 41, 163, .2);
    transition: 0.5s;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left{
    padding: 8px 12px;
    width: 100%;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-caption-box,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-title-box{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-0 .restaurent-product-label,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box .restaurent-product-title{
    margin-left: 15px;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-caption-box,
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-product-box .restaurent-product-label{
    align-self: flex-start;
    margin-bottom: 10px;
}
.restaurent-product-list .restaurent-product-detail span {
    word-break: break-all;
}
.restaurent-product-list .restaurent-product-detail .restaurent-tags {
    display: flex;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price{
    align-items: center;
}
.restaurent-product-list .restaurent-product-rating {
    text-align: center;
    padding: 0 10px;
    padding-left: 0;
}
.restaurent-product-list .restaurent-product-rating .ratings {
    display: flex;
}
.restaurent-product-list .restaurent-product-rating .ratings>i {
    line-height: unset;
}
.restaurent-product-list .restaurent-product-rating .rating-text p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.restaurent-product-list .restaurent-product-img {
    width: 150px;
    flex-basis: 150px;
    line-height: 0;
    height: 100%;
}
.restaurent-product-list .restaurent-product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.restaurent-product-list .restaurent-product-detail .restaurent-product-left .restaurent-tags-price .circle-tag{
    position: absolute;
    top: 8px;
    right: 12px;
}

.sidebar-card.card {
    margin: 4px;
}
.sidebar-card .offer-content {
    text-align: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 20px 10px 10px;
    position: relative;
    min-height: 100%;
    background: linear-gradient(0deg, #6a1d89a8, #6a1d89a8), url('../img/offer.jpg');
    background-size: cover;
}

.sidebar-card .offer-content h2 {
    text-transform: uppercase;
    font-size: 50px;
}
.sidebar-card .offer-content h2 small {
    display: block;
    font-size: 30px;
    text-transform: lowercase;
}
/*Restaurent About*/

.restaurent-about .about-restaurent {
    margin-top: 10px;
    background-color: #f8fafa;

}
.restaurent-about .about-restaurent li {
    margin-bottom: 10px;
    position: relative;
}
.restaurent-about .about-restaurent li i {
    position: absolute;
    left: 0;
    top: 5px;
    font-size: 16px;
    
}
.restaurent-about .about-restaurent li span {
    position: relative;
    left: 30px;
}
.restaurent-schdule .card,
.restaurent-schdule .card .card-header {
    border: none;
    border-radius: 0;
}
.restaurent-schdule .card .card-body {
    padding: 20px 20px 10px 20px;
}
.restaurent-schdule .card .card-body .schedule-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.restaurent-schdule .card .card-footer {
    background: #fff;
}
.social-media {
    display: flex;
    align-items: center;
}
.social-media li {
    width: 20%;
    border: 1px solid #ccc;
    border-radius: 0;
    border-right: 0;
}
.social-media li:last-child {
    border: 1px solid #ccc;
}
.social-media li a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}
.social-media li:hover {
    background: #ff0018;
    transition: 0.5s;
}
.social-media li:hover a {
    color: #fff;
}
/*Restaurent Review*/

.restaurent-review ul {
    display: flex;
    margin: 0 -15px 20px;
}
.restaurent-review ul li {
    padding: 0 15px;
    max-width: 110px;
}
.restaurent-review .review-img {
    max-width: 540px;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    padding-bottom: 0;
}
.restaurent-review .review-img img {
    margin-bottom: 30px;
}
.review-box {
    padding: 20px 0;
}
.review-box.comment-reply{
    margin-left: 60px;
}
.review-box .review-user {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.review-box .ratings,
.review-box .review-user {
    margin-bottom: 10px;
}
.review-box .food-order{
    margin-bottom: 5px;
    display: block;
}
.review-box .review-user .review-user-img {
    display: flex;
    align-items: center;
    width: 3rem;
}
.review-box .review-user .review-user-img img {
    margin-right: 12px;
}
.review-box .review-user .review-user-img .reviewer-name p {
    margin-bottom: 5px;
    line-height: normal;
}
.review-box .review-user .review-user-img .reviewer-name p small {
    margin-left: 10px;
}
.review-box .review-user .review-user-img .reviewer-name span {
    font-size: 11px;
    vertical-align: text-bottom;
    margin-left: 3px;
}
.review-box .food {
    display: flex;
    flex-wrap: wrap;
    margin: auto 0;
}
.review-box .food li {
    max-width: 100%;
    padding: 0;
}
.review-box .food li button.add-pro {
    cursor: pointer;
    margin-right: 10px;
    color: #fff;
    background-color:  rgba(0, 0, 0, .05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
    margin-bottom: 10px;
}
.review-box .food li button.add-pro span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 22px;
    top: 50%;
    transform: translate(0px, -50%);
    text-align: right;
    padding: 0;
    color: #fff;
    font-weight: bold;
    opacity: 1;
}
.restaurent-popup .modal-content {
    min-height: 85vh;
    background: #fbfbfc;
    overflow-y: auto;
}
.restaurent-popup .modal-header {
    display: block;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    padding: 0;
    height: 200px;
}
.restaurent-popup .modal-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.restaurent-popup .close {
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    color: #ff0018;
    opacity: 1;
    padding: 0;
    font-size: 35px;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 20px;
    position: absolute;
    padding: 20px;
}
.restaurent-popup .modal-title {
    font-size: 20px;
}
.restaurent-popup .modal-body {
    padding: 0;
    height: 550px;
    overflow: auto;
}
.product-quantity {
    display: flex;
    align-items: center;
}
.product-quantity span {
    margin-right: 20px;
}
.product-quantity .quantity {
    border: 1px solid #545470;
    border-radius: 19px;
    flex-grow: 0;
    overflow: hidden;
    position: relative;
    height: 38px;
    width: auto;
}
.product-quantity .quantity button {
    padding: 0 15px;
}
.product-quantity .quantity button:focus {
    outline: none;
}
.product-quantity .quantity input {
    border: none;
    width: 40px;
}
/*additional-product*/

.additional-product .card {
    border-radius: 0;
    border: none;
}
.additional-product .card .card-header {
    border-radius: 0;
    background: transparent;
    padding: 10px;
}
.additional-product .card .card-header .card-link {
    font-size: 18px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0;
}
.additional-product .card .card-header .card-link span{
    display: block;
}
.additional-product .card .card-header .card-link:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 25px;
}
.additional-product .card .card-header .card-link.collapsed:after {
    content: '\f078';
}
.additional-product .card .card-body button.add-pro {
    cursor: pointer;
    margin-right: 10px;
    color: rgba(0, 0, 0, .87);
    background: rgba(0, 0, 0, .05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
    margin-bottom: 10px;
}
.additional-product .card .card-body button.add-pro:hover {
    background: #ccc;
}
.additional-product .card .card-body button.add-pro span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 22px;
    top: 50%;
    transform: translate(0px, -50%);
    text-align: right;
    padding: 0;
    color: #000;
    font-weight: bold;
}
.restaurent-popup .modal-footer {
    justify-content: space-between;
}
/*map-gallery-se*/
.map-gallery-sec .main-box #locmap {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    z-index: 100;
    pointer-events: none;
    border:none;
}
.map-gallery-sec .map-pl-0{
    padding-left: 0;
}
.map-gallery-sec .map-pr-0{
    padding-right: 0;
}
.map-gallery-sec .gallery-box .gallery-img {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: -5px;
}
.map-gallery-sec .gallery-box .gallery-img li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 5px;
}
.smoothscroll.active {
    position: relative;
    padding-top: 150px;
}
.smoothscroll.active#menu {
    padding-top: 0;
}
/*video box*/
.video-box iframe {
    width: 100%;
    border: none;
    height: 250px;
}
.video_wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #000;
    height: 250px;
}
.videoIframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
}
.videoPoster {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('../img/video-banner.jpg');
    background-size: cover;
    border: none;
    background-position: center;
    overflow: hidden;
    opacity: 1;
    transition: opacity 100ms, height 0s;
    transition-delay: 0s, 0s;
}
.video_wrapper:hover .video-inner i{
    color: #bc2634;
    transition: 0.5s;
}
.videoPoster:hover {
    cursor: pointer;
}
.videoPoster .video-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.videoPoster .video-inner i {
    font-size: 70px;
    color: #fff;
}
.videoWrapperActive .videoPoster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
}
.discount-box{
    margin-top: -6px;
}
.discount-box .discount-price .right-side,
.discount-box .discount-price{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.discount-box .discount-price .right-side .dis-text{
    margin-right: 25px;
}
.discount-box .discount-price .right-side,
.discount-box .discount-price .left-side{
    max-width: 50%;
    flex-basis: 50%;
}
/*side bar cart*/
.sidebar .cart-detail-box {
    width: 100%;
    background: #fff;
    position: relative;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    cursor: pointer;
}
.sidebar .cart-detail-box>.card {
    border: none;
}
.sidebar .cart-detail-box>.card .card-header,
.sidebar .cart-detail-box>.card .card-footer {
    background: #fff;
}
.sidebar .cart-detail-box>.card .card-body {
    height: 350px;
    overflow-y: auto;
}
.sidebar .cart-detail-box>.card>.modify-order>.total-amount,
.sidebar .cart-detail-box>.card .card-body .item-total .total-price,
.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name a p {
    margin-bottom: 0px;
}
.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name a p span {
    margin-right: 10px;
}
.sidebar .cart-detail-box>.card .card-body .cat-product-box .cat-product .cat-name {
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.sidebar .cart-detail-box>.card>.modify-order>.total-amount {
    background-color: #8f8fa1;
}
/*======================
6. Add Restaurent
========================*/
/*add restaurent*/
.register-restaurent-sec {
    counter-reset: section;
}
#add-restaurent-tab .step-steps > li:after {
    counter-increment: section;
    content: counter(section);
    position: absolute;
    top: 16px;
    font-size: 10px;
    left: 0;
    right: 0;
    pointer-events: none;
    font-weight: 600;
    color: #000;
}
#add-restaurent-tab .step-steps > li.go2998626915:after,
#add-restaurent-tab .step-steps > li.done:after,
#add-restaurent-tab .step-steps > li.active:after {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #fff;
    font-size: 7px;
    top: 20px;
}
#add-restaurent-tab .step-footer .none{
    display: none;
}
#add-restaurent-tab .step-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 0px;
}
#add-restaurent-tab .step-footer #next-1{
    margin: 0px 0px 0px auto;
}
#add-restaurent-tab .steps-1 {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
}
.step-app > .steps-1 {
    overflow: hidden;
    margin: 0 auto;
}
#add-restaurent-tab .steps-1 > li {
    flex: 0 0 25%;
    max-width: 25%;
}
.step-app .steps-1 > li {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
}
#add-restaurent-tab .steps-1 > li:before {
    background-color: #e3e3e3;
    height: 12px;
    left: 45%;
    top: 19px;
    width: 100%;
    transition: all 0.3s;
    margin-left: 4px;
}
#add-restaurent-tab .steps-1 > li:first-child:before {
    border-radius: 10px 0 0 10px;
}
.step-app .steps-1 > li:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 2px;
    background-color: #C4CAD2;
    top: 35px;
    left: 85px;
}
.step-app .steps-1 > li > a,
.step-app .steps-1 > li > a > span {
    display: block;
}
#add-restaurent-tab .steps-1 > li > a > .number {
    width: 20px;
    height: 20px;
    background-color: #b6b6b6;
    border: 0px solid;
    transition: all 0.3s;
}
.step-app .steps-1 > li > a > .number {
    border-radius: 50%;
    color: #b6b6b6;
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    margin: 10px auto;
    position: relative;
    z-index: 0;
    top: 5px;
}
#add-restaurent-tab .step-steps > li.go2998626915 > a > .number,
#add-restaurent-tab .steps-1 > li.active > a > .number,
#add-restaurent-tab .steps-1 > li.done > a > .number {
    background-color: #003d00;
}
#add-restaurent-tab .steps-1 > li > a > .step-name {
    font-weight: 600;
    color: #ff0018;
    font-size: 12px;
    margin-top: 20px;
    transition: all 0.3s;
}
#add-restaurent-tab .steps-1 > li:last-child:before {
    width: 0;
}
#add-restaurent-tab .step-steps > li.go2998626915:before,
#add-restaurent-tab .steps-1 > li.done:before {
    background-color: #00a000;
}
#add-restaurent-tab .steps-2 {
    margin: 20px 0;
}
#add-restaurent-tab .steps-2 > li {
    display: block;
    margin-bottom: 10px;
}
#add-restaurent-tab .steps-2 > li:before,
#add-restaurent-tab .steps-2 > li:after {
    display: none;
}
#add-restaurent-tab .steps-2 > li > a {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.595;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    border-color: rgba(67, 41, 163, .2);
    background: #fff;
    font-weight: 600;
}
#add-restaurent-tab .steps-2 > li.go2998626915 a,
#add-restaurent-tab .steps-2 > li.go2998626915 a {
    color: #fff;
    background: #ff0018;
    border-color: #ff0018;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel {
    display: none;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel.active {
    display: block;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel label sup {
    top: 0;
    color: #ff0000;
}
#add-restaurent-tab.step-app .step-content .step-tab-panel .form-group .form-group {
    display: flex;
    margin-bottom: 35px;
}

.package-box .package-caption{
    border-radius: 0 0 3px 3px;
}
.package-box .package-caption .package-item-first{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.package-box .package-caption>ul>li:before{
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    left: 0;
    top: 50%;
    margin-top: 4px;
}
.package-box .package-caption>ul>li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(210,201,255,0.1);
    position: relative;
    padding-left: 15px;
    letter-spacing: 0.4px;
}
.package-box .package-caption>ul>li:first-child{
    border:none;
}
.package-box .package-caption .package-btn{
    margin-top: 20px;
}
.general-sec iframe{
    border:none;
}
.payment-sec .card-header,
.payment-sec .card {
    border: none;
    border-radius: 0;
}
.payment-sec .card-header {
    background: #fff;
    padding-left: 0;
    padding-top: 0;
}
.payment-sec .card-header .card-link.collapsed:before {
    content: '+';
}
.payment-sec .card-header .card-link:before {
    content: '+';
    font-size: 18px;
    line-height: normal;
    position: relative;
    top: 2px;
    right: 0;
    padding-right: 10px;
    font-weight: 700;
}
.driver-tip-sec>.nav,
.payment-option-tab>.nav {
    margin-bottom: 1rem;
}
.payment-option-tab>.nav>.nav-item {
    flex: 0 0 20%;
    max-width: 20%;
}
.driver-tip-sec>.nav>.nav-item>.nav-link,
.payment-option-tab>.nav>.nav-item>.nav-link {
    padding: .5rem;
    text-align: center;
    border: 1px solid rgba(67, 41, 163, .2);
    border-right: 0;
    border-radius: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.driver-tip-sec>.nav>.nav-item:last-child>.nav-link,
.payment-option-tab>.nav>.nav-item:last-child>.nav-link {
    border: 1px solid rgba(67, 41, 163, .2);
}
.driver-tip-sec>.nav>.nav-item>.nav-link.active,
.payment-option-tab>.nav>.nav-item>.nav-link.active {
    color: #fff;
    background: #ff0018;
    border-color: #ff0018;
}
.payment-option-tab .credit-card:before {
    content: '';
    position: absolute;
    padding: 15px 25px;
    font-size: 18px;
    color: #6b6b83;
    pointer-events: none;
    background-image: url(../img/visa.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    top: 8px;
    left: 8px;
}
.payment-option-tab .credit-card.card-front:before {
    background-image: url(../img/card-front.jpg);
}
.payment-option-tab .credit-card.card-back:before {
    background-image: url(../img/card-back.jpg);
}
.payment-option-tab .credit-card.gift-card:before {
    background-image: url(../img/gift-box.png);
}
.payment-option-tab .credit-card.promocode:before {
    background-image: url(../img/promo.jpg);
}
.payment-option-tab .credit-card.amex-card-front:before {
    background-image: url(../img/amex-card-front.png);
}
.payment-option-tab .credit-card.amex-card-back:before {
    background-image: url(../img/amex-card-back.png);
}
.payment-option-tab .credit-card input {
    padding-left: 65px;
}
.driver-tip-sec>.nav>.nav-item {
    flex: 0 0 50%;
    max-width: 50%;
}
.driver-tip-sec .tip-percentage form {
    display: flex;
    flex-wrap: wrap;
}
.driver-tip-sec .tip-percentage input[type=radio]:checked + span {
    background: #ff0018;
    color: #fff;
}
.driver-tip-sec .tip-percentage label {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
    z-index: 1;
    cursor: pointer;
}
.driver-tip-sec .tip-percentage label span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 13px .5rem;
    z-index: -1;
    text-align: center;
    border: 1px solid rgba(67, 41, 163, .2);
    border-right: 0;
    border-radius: 0;
}
.driver-tip-sec .tip-percentage label input {
    -webkit-appearance: none;
}
.driver-tip-sec .tip-percentage label:last-child span {
    border: 1px solid rgba(67, 41, 163, .2);
}
.driver-tip-sec .custom-tip .input-group .input-group-prepend {
    border: 1px solid #8f8fa1;
}
.driver-tip-sec .custom-tip .input-group .input-group-prepend span {
    border-radius: 0;
}
.thankmsg-sec .msg-wrapper h1 {
    font-size: 60px;
}

.thankmsg-sec .msg-wrapper .contact-details {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
.thankmsg-sec .msg-wrapper .contact-details>li {
    font-size: 16px;
    margin: 0 25px;
}
.thankmsg-sec .msg-wrapper .contact-details>li>i {
    padding-right: 15px;
}

/*======================
7. Add Restaurent
========================*/
/*======================
3. List View
========================*/
.most-popular .popular-item-slider .swiper-slide {
    margin-bottom: 20px;
}
.sort-tag-filter,
.product-list-view,
.most-popular .popular-item-slider {
    border-bottom: 1px solid rgba(67, 41, 163, .1);
}
.sort-tag-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.restaurent-tags span.tags {
    cursor: pointer;
    margin-right: 10px;
    color: rgba(0, 0, 0, .87);
    background: rgba(0, 0, 0, .05);
    text-transform: capitalize;
    padding: 3px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    padding-right: 35px;
    font-weight: 500;
}
.restaurent-tags span.tags:hover {
    color: #ff0018;
}
.restaurent-tags span.tags span.close {
    position: absolute;
    right: 10px;
    font-weight: 700;
    font-size: 14px;
    top: 50%;
    transform: translate(0px, -50%);
}
.sorting span {
    margin-right: 10px;
}
.sorting select {
    color: #00000059;
    border: 2px solid #ccc;
    padding: 3px 40px 3px 8px;
    border-radius: 3px;
    text-align: left;
    font-weight: 600;
}
/*filter-sidebar*/

.filter-sidebar .title-2 small {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-sidebar .title-2 small a {
    text-decoration: underline;
}
.filter-sidebar .sidebar-tab>.nav>.nav-item {
    margin-right: 28px;
    border-radius: 0;
}
.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link {
    color: rgba(0, 0, 0, .55);
    border-radius: 0;
    border-bottom: 4px solid transparent;
    font-weight: 700;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
}
.filter-sidebar .sidebar-tab>.nav>.nav-item>.nav-link.active {
    background: transparent;
    color: rgb(255, 249, 249);
    border-bottom: 4px solid #ff0018;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav {
    display: flex;
    margin-bottom: 10px;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item {
    width: 50%;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link {
    flex: 1;
    white-space: normal;
    border: 1px solid transparent;
    transition-property: z-index, background, border-color, color;
    transition-timing-function: ease;
    transition-duration: .1s;
    border-color: rgba(67, 41, 163, .2);
    color: #ff0018;
    background-color: transparent;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    padding: 4px 0;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link.disabled {
    color: #ccc;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item>.nav-link.active {
    border-color: #ff0018;
    color: #fff;
    background-color: #ff0018;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item:first-child>.nav-link {
    border-radius: 4px 0 0 4px;
}
.filter-sidebar .sidebar-tab .tab-content .siderbar-innertab>.nav>.nav-item:last-child>.nav-link {
    border-radius: 0px 4px 4px 0px;
}
.filter-sidebar .sidebar-tab .tab-content p.delivery-type a:before {
    content: '';
    width: 2px;
    height: 2px;
    background: #000;
    display: inline-flex;
    margin: 0 8px;
    vertical-align: middle;
}
.filter-sidebar .sidebar-tab .filters .card-header,
.filter-sidebar .sidebar-tab .filters .card {
    border: none;
    padding: .75rem 0;
    background: rgba(0, 0, 0, 0.05);
}
.filter-sidebar .sidebar-tab .filters .card {
    padding: 0;
    margin-bottom: 25px;
}
.filter-sidebar .sidebar-tab .filters .card .card-body {
    padding: 0;
}
.filter-sidebar .sidebar-tab .filters .card-header a {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
}
.filter-sidebar .sidebar-tab .filters .card-header a:after {
    content: '\f077';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
.filter-sidebar .sidebar-tab .filters .card-header a.collapsed:after {
    content: '\f078';
}
.filters .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filters .rating button {
    border: 1px solid rgb(255, 255, 255);
    padding: 6px 10px;
    font-size: 12px;
    line-height: 19px;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    display: block;
    border-right: 0;
    font-weight: 900;
}
.filters .rating button:hover {
    background: #ff0018;
    color: #fff;
    border-color: #ff0018;
}
.filters .rating button:first-child {
    border-radius: 3px 0 0 3px;
}
.filters .rating button:last-child {
    border-radius: 0px 3px 3px 0px;
    border-right: 1px solid #ccc;
}
.filters .delivery-slider .irs--flat .irs-line,
.filters .delivery-slider .irs--flat .irs-bar {
    height: 2px;
    top: 30px;
    cursor: pointer;
}
.filters .delivery-slider .irs--flat .irs-bar {
    background-color: #ff0018;
}
.filters .delivery-slider .irs--flat .irs-grid-text {
    font-size: 16px;
    color: #000;
    padding: 0 0px 0 25px;
    top: 25px;
}
.filters .delivery-slider .irs-grid-pol.small,
.filters .delivery-slider .irs--flat .irs-grid-pol {
    display: none;
}
.filters .delivery-slider .irs--flat .irs-handle {
    width: 0;
}
.filters .delivery-slider .irs--flat .irs-handle>i:first-child {
    top: 50%;
    width: 25px;
    height: 25px;
    background-color: #ff0018;
    border-radius: 50%;
    transform: translate(0px, -50%);
    cursor: pointer;
}
.filters .delivery-slider .irs--flat .irs-handle.state_hover>i:first-child,
.filters .delivery-slider .irs--flat .irs-handle:hover>i:first-child {
    background-color: #bc2634;
}
.filters .delivery-slider .irs--flat .irs-from,
.filters .delivery-slider .irs--flat .irs-to,
.filters .delivery-slider .irs--flat .irs-single {
    background-color: #ff0018;
}
.filters .delivery-slider .irs--flat .irs-from:before,
.filters .delivery-slider .irs--flat .irs-to:before,
.filters .delivery-slider .irs--flat .irs-single:before {
    border-top-color: #ff0018;
}
.delivery-slider .irs--flat.irs-with-grid {
    width: 95%;
}
/*testimonal layout*/
.swiper-slide .testimonial-wrapper {
    margin-top: 0;
    background-color: #FFC04B;
}
.video-box,
.testimonial-wrapper .testimonial-box {
    position: relative;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    cursor: pointer;
}
.testimonial-wrapper .testimonial-box .testimonial-img .brand-logo {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    background: #fff;
    width: 50px;
    height: 50px;
    transform: translate(15px, -50%);
}
.testimonial-wrapper .testimonial-box .testimonial-img .add-fav {
    position: absolute;
    right: 0;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    background: #fff;
    width: 35px;
    height: 30px;
    transform: translate(-15px, 0%);
    bottom: -15px;
    border-radius: 5px 5px 0 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.testimonial-wrapper .testimonial-box .testimonial-img .add-fav>img{
    padding: 8px;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box img {
    margin-right: 12px;
    margin-bottom: 8px;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box .testimonial-user-name p {
    margin-bottom: 0px;
    line-height: normal;
}
.testimonial-wrapper .testimonial-box .testimonial-user-box .testimonial-user-name span {
    font-size: 11px;
    margin-left: 3px;
}
/*list view*/
.product-list-view:first-child {
}
.product-list-view {
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.product-list-view:last-child{
    margin-bottom: 20px;
}
.product-list-view:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: 0.5s;
}
.product-list-view .product-list-info {
    width: 210px;
}
.product-list-view .product-right-col {
    width: 95%;
}
.product-list-view .product-list-title h6 {
    margin-bottom: 5px;
}
.product-list-view .product-list-info .product-list-img {
    flex: 0 0 90px;
    overflow: hidden;
    height: 150px;
}
.product-list-view .product-list-info .product-list-img img{
    width:100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.product-list-view .product-list-bottom,
.product-list-view .product-list-details {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.product-list-view .product-list-details .product-list-title {
    align-content: space-between;
    padding-left: 20px;
    max-width: 100%;
}
.product-list-view .product-list-details .product-detail-right-box {
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.product-list-view .product-list-details .product-detail-right-box .product-list-tags{
    display: flex;
    flex-wrap: wrap;

}
.product-list-view .product-right-col .product-list-bottom .mob-tags-label {
    display: none;
}
.product-list-view .product-list-details .product-detail-right-box .product-list-label span {
    margin: 0;
    margin-bottom: 2px;
}
.product-list-view .product-list-details .product-detail-right-box .product-list-price .product-list-time {
    display: none;
}
.product-list-view .product-list-details .product-list-price {
    text-align: right;
}
.product-list-view .product-list-time,
.product-list-view .product-list-time ul {
    display: flex;
    align-items: center;
    margin: 10px;

    justify-content: flex-end;
}
.product-list-view .product-list-time ul li {
    padding: 0 10px;
    position: relative;
    
}
.product-list-view .product-list-time ul li:after {
    content: '';
    background: #6b6b83;
    width: 3px;
    height: 3px;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
}
.product-list-view .product-list-time ul li:last-child {
    padding-right: 0;
}
.product-list-view .product-list-time ul li:last-child:after {
    display: none;
}
.product-list-view .product-list-bottom .product-list-type {
    padding-left: 20px;
}
.product-list-view .product-list-bottom .product-list-type span {
    display: inline-block;
}
.product-list-view .product-list-time {
    width: 27%;
    justify-content: space-between;
}
.product-list-view .product-list-time>span {
    margin-right: 20px;
}
/*=========================
8. Login & register
===========================*/
.user-page .login-sec {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
}
.user-page .login-sec .login-box {
    max-width: 420px;
    width: auto;
    margin: 0 auto;
    margin-top: 0px;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    background: #fff;
    padding: 30px;
}
.user-page .login-sec .login-box .checkbox-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-page .login-sec .login-box span.terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.user-page .login-sec .login-box span.terms a {
    margin: 0 3px;
}
.user-page .login-sec .login-box .form-group .field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
    right: 18px;
    cursor: pointer;
}
/*=========================
9. Checkout
===========================*/

.recipt-sec,
.tracking-sec {
    display: flex;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
    border-radius: 3px;
    background: #fff;
    margin-bottom: 20px;
}
.recipt-sec {
    display: block;
    margin-bottom: 0;
}
.tracking-sec .tracking-map,
.tracking-sec .tracking-details {
    flex: 0 0 50%;
    max-width: 50%;
}
#add-listing-tab1 .step-steps,
#add-listing-tab .step-steps {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 0;
}
.step-app > .step-steps {
    overflow: hidden;
    margin: 0 auto;
}
#add-listing-tab1 .step-steps > li,
#add-listing-tab .step-steps > li {
    flex: 0 0 25%;
    max-width: 25%;
}
.step-app > .step-steps > li {
    display: inline-block;
    position: relative;
    z-index: 1;
    text-align: center;
}
#add-listing-tab1 .step-steps > li:before,
#add-listing-tab .step-steps > li:before {
    background-color: #e3e3e3;
    height: 12px;
    left: 45%;
    top: 19px;
    width: 100%;
    transition: all 0.3s;
    margin-left: 4px;
}
#add-listing-tab1 .step-steps > li:first-child:before,
#add-listing-tab .step-steps > li:first-child:before {
    border-radius: 10px 0 0 10px;
}
.step-app > .step-steps > li:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 2px;
    background-color: #C4CAD2;
    top: 35px;
    left: 85px;
}
.step-app > .step-steps > li > a,
.step-app > .step-steps > li > a > span {
    display: block;
}
#add-listing-tab1 .step-steps > li > a > .number,
#add-listing-tab .step-steps > li > a > .number {
    width: 12px;
    height: 12px;
    background-color: #b6b6b6;
    border: 0px solid;
    transition: all 0.3s;
}
.step-app > .step-steps > li > a > .number {
    border-radius: 50%;
    color: #b6b6b6;
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
    margin: 10px auto;
    position: relative;
    z-index: 0;
    top: 9px;
}
#add-listing-tab1 .step-steps > li.active > a > .number,
#add-listing-tab1 .step-steps > li.done > a > .number,
#add-listing-tab .step-steps > li.active > a > .number,
#add-listing-tab .step-steps > li.done > a > .number {
    background-color: #003d00;
}
#add-listing-tab1 .step-steps > li > a > .step-name,
#add-listing-tab .step-steps > li > a > .step-name {
    font-weight: 600;
    color: #ff0018;
    font-size: 12px;
    margin-top: 20px;
    transition: all 0.3s;
    white-space: normal;
}
#add-listing-tab1 .step-steps > li:last-child:before,
#add-listing-tab .step-steps > li:last-child:before {
    width: 0;
}
#add-listing-tab1 .step-steps > li.done:before,
#add-listing-tab .step-steps > li.done:before {
    background-color: #00a000;
}
.tracking-sec .tracking-map #pickupmap {
    height: 100%;
    width: 100%;
    border: none;
    min-height: 300px;
}
.tracking-sec .tracking-details .fullpageview {
    position: absolute;
    bottom: 20px;
}
.md-modal {
    margin: auto;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 320px;
    height: 100%;
    z-index: 2000;
    visibility: hidden;
    backface-visibility: hidden;
}
.md-show {
    visibility: visible;
}
.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: #fff;
    transition: all 0.3s;
}
.md-show ~ .md-overlay {
    opacity: 1;
    visibility: visible;
}
.md-effect-12 .md-content {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
    height: 100%;
}
.md-show.md-effect-12 ~ .md-overlay {
    background-color: #fff;
}
.md-show.md-effect-12 .md-content {
    transform: scale(1);
    opacity: 1;
}
.md-modal .md-content #pickupmap2 {
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li:after {
    content: "";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #00a000;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    position: absolute;
    top: 0;
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li.order-note:after {
    content: "\f328";
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li.make-order:after {
    content: "\f447";
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li.bike:after {
    content: "\f21c";
}
.md-modal .md-content .modal-sidebar #add-listing-tab1 .step-steps > li.home-delivery:after {
    content: "\f015";
}
.md-modal .md-content .modal-sidebar .arrow {
    position: absolute;
    right: 50px;
}
.md-modal .md-content .modal-sidebar .rating-box .ratings span {
    cursor: pointer;
}
.md-modal .md-content .modal-sidebar .rating-box .ratings span:hover i {
    color: #ff0018;
}
.recipt-sec .recipt-name.title {
    align-items: center;
    padding-bottom: 20px;
}
.countdown-box {
    display: flex;
}
.countdown-box .time-box {
    text-align: center;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: #ff0018;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    font-size: 22px;
    box-shadow: 0 0 0 1px rgba(67, 41, 163, .08), 0 1px 5px 0 rgba(67, 41, 163, .08);
}
.countdown-box .time-box span{
    line-height: 1;
}
.countdown-box .time-box span h6 {
    margin-top: 5px;
    color: #fff;
}
.recipt-sec .recipt-name span {
    display: block;
}
.checkout-product {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}
.checkout-product .img-name-value {
    display: flex;
    align-items: flex-start;
}
.checkout-product .img-name-value .product-value {
    margin: 0 30px;
}
.payment-method .method-type {
    display: flex;
    align-items: center;
}
.payment-method .method-type i {
    font-size: 40px;
    margin-right: 15px;
}
.price-table .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkout-page .help-btn {
    margin: 0px 0;
}
.checkout-page .d-flex {
    align-items: center;
    justify-content: center;
}
/*=========================
10. Final Order
===========================*/
.final-order .title2 span {
    margin-left: 140px;
}
.final-order .advertisement-img,
.final-order .advertisement-img img {
    height: 100%;
}
/*=========================
11. Geo Locator
===========================*/

.inner-wrapper {
    height: inherit;
    position: relative;
}
.main-padding {
    padding: 64px 100px;
}
.section-2,
.main-banner {
    height: 100vh;
}
.main-banner .main-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.main-banner .footer-img {
    position: absolute;
    bottom: 0;
    max-width: 440px;
    right: 0;
}
.inner-wrapper .main-page .top-nav {
    position: absolute;
    top: 64px;
    display: flex;
    right: 70px;
}
.inner-wrapper .main-page .top-nav h5 {
    margin-right: 30px;
}
.inner-wrapper .main-page .login-box {
    height: 100%;
    display: flex;
    align-items: center;
}
.inner-wrapper .main-page .login-box .input-group-prepend {
    height: 47px;
    min-width: 44px;
    flex-basis: 44px;
    border: 1px solid #8f8fa1;
    border-left: 0;
    border-radius: 0px 4px 4px 0;
    display: none;
}
.inner-wrapper .main-page .login-box .input-group-prepend .input-group-text {
    line-height: 1.5;
    font-size: 20px;
    background: transparent;
    border: none;
}
.inner-wrapper .main-page .login-box .input-group .input-group2 {
    display: flex;
}
.inner-wrapper .main-page .login-box .input-group {
    margin: 0 -4px
}
.inner-wrapper .main-page .login-box .input-group .input-group2,
.inner-wrapper .main-page .login-box .input-group-append {
    padding: 0 8px;
}
.inner-wrapper .main-page .login-box .input-group-append button{
    white-space: normal;
}
/*=========================
12. About Us
===========================*/
/*slider*/
.about-us-slider .slide-item {
    height: 500px;
}
.about-us-slider .slide-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.about-us-slider .slide-item .right-side-content h1 {
    font-size: 60px;
    line-height: 1.2;
}
.about-us-slider .slide-item .right-side-content{
    transition: all 1s linear;
    transition-delay: 1s;
}
.about-us-slider .slide-item .right-side-content h3,
.about-us-slider .slide-item .right-side-content a,
.about-us-slider .slide-item .right-side-content h1{
  transition: all .8s cubic-bezier(0.215, 0.61, 0.355, 1) 1.1s;
  transform: translate3d(-20%, 0, 0);
  opacity: 0;
}
.about-us-slider .slide-item.swiper-slide-active .right-side-content a,
.about-us-slider .slide-item.swiper-slide-active .right-side-content h3,
.about-us-slider .slide-item.swiper-slide-active .right-side-content h1{
    transform: translate3d(0, 0, 0);
    opacity: 1;
}
/*aboutus*/
.aboutus .history-title{
    margin-top: -7px;
}
.aboutus .history-title>a{
    margin-top: 20px;
}
/*how it works*/
.how-it-works .how-it-works-box{
  text-align: center;
  padding: 20px 0px;
  cursor: pointer;
}
.how-it-works .how-it-works-box.arrow-1:after{
    content: '';
    width: 100%;
    background: url(../img/arrow-2.svg) no-repeat;
    position: absolute;
    right: 0;
    z-index: 1;
    background-size: contain;
    background-position: right;
    height: 70px;
    top: -35px;
    transform: rotate(50deg);
    left: 65px;
}
.how-it-works .how-it-works-box.arrow-2:after{
    content: '';
    width: 100%;
    background: url(../img/arrow-3.svg) no-repeat;
    position: absolute;
    right: 0;
    z-index: 1;
    background-size: contain;
    background-position: right;
    height: 70px;
    top: -20px;
    transform: rotate(35deg);
    left: 50px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner{
  max-width: 250px;
  margin: 0 auto;
}
.how-it-works .how-it-works-box .how-it-works-box-inner h6,
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box{
  margin-bottom: 15px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #ff0018;
  margin: 0 auto 15px;
  position: relative;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box img{
  padding: 30px;
}
.how-it-works .how-it-works-box .how-it-works-box-inner .icon-box .number-box{
    position: absolute;
    top: 0;
    left: 10px;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    font-size: 12px;
}
/*feedback-area-two*/
.feedback-area-two{
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: #000000;
    opacity: 0.9;
}
.feedback-area-two .feedback-item-two {
    max-width: 800px;
    margin: 15px auto 0;
}
.feedback-area-two .feedback-item-two img {
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    border: 4px solid #ffffff;
    margin-bottom: 17px;
}
.feedback-area-two .feedback-item-two p {
    font-style: italic;
    margin-bottom: 15px;
}
.feedback-area-two .feedback-item-two span {
    line-height: 1;
    display: block;
    margin-top: 9px;
}

/*side-panel*/
#side-panel{
    position: fixed;
    width: 125px;
    top: 50%;
    left: -1px;
    min-height: 300px;
    transition: 0.5s ease-in-out;
    z-index: 999;
}
#side-panel .themes-wrapper{
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    border:1px solid #d8d8d8;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    position: relative;
}
#side-panel .themes-wrapper h6{
    padding: 14px 0;
    color: #3d322c;
    margin-bottom: 0;
    font-size: 22px;
    text-align: center;
}
#side-panel.toggled{
    left: -125px;
    transition: 0.5s ease-in-out;
}
#side-panel .themes-wrapper .vertical-link{
    position: absolute;
    left: 123px;
    top: 52px;
    border-radius: 0;
    border: 1px solid #000;
    border-top: 0;
    font-size: 14px;
    text-decoration: none;
    color: #fff;
    width: 153px;
    height: 47px;
    line-height: 46px;
    background-color: #000;
    text-align: center;
    padding: 0 5px;
    transform-origin: 0 50%;
    transform: rotate(-90deg) translate(-50%, 50%);
}
#side-panel .themes-wrapper-inner{
    padding: 10px 5px 10px 10px;
}
#side-panel .themes-wrapper-inner a{
    display: block;
    float: none;
    margin: 0 4px 5px 0;
}
#side-panel .themes-wrapper-inner .btn-second{
    width: 104px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}

/* ....................................
non use css(Color-Theme)
.......................................*/

/*switch color css*/

.color-theme {
    position: fixed;
    top: 35%;
    z-index: 3333;
    left: -209px;
}

.theme-colors {
    width: 209px;
    background-color: #fff;
    padding: 18px 20px 15px 28px;
    padding-bottom: 6px;
    box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.25)
}

ul.theme-ul li {
    display: inline-block;
    width: 26%;
    margin-bottom: 13px;
    margin-right: 5%;
}

ul.theme-ul li img {
    width: 100%;
}

.theme-colors h4 {
    font-size: 15px;
    font-weight: bold;
    color: #16202b;
    margin-bottom: 10px;
}

.theme-switch {
    position: absolute;
    right: -50px;
    top: 0;
    background-color: #000;
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}
.color-theme:hover{
    left: 0;
}
.each-color{
    cursor: pointer;
    display: inline-block;
}
.theme-colors p {
    line-height: 19px;
}

.transition-4,
.transition-4:hover {
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
    transition: all .4s ease-out;
}

.theme-ul {
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
}
.theme-ul li {
    display: inline-block;
    width: 26%;
    margin-bottom: 13px;
    margin-right: 5%;
}
.each-color {
    cursor: pointer;
    display: inline-block;
}
.theme-ul li img {
    width: 100%;
}
.theme-colors p {
    line-height: 19px;
}
.rangeslider-horizontal{
    height: 4px;
    border-radius: 0;
    box-shadow: none;
}
.rangeslider-horizontal .rangeslider__fill{
    background-color: #ff0018;
    box-shadow: none;
}
.rangeslider__labels .rangeslider__label-item{
    font-size: 10px;
}
.rangeslider-horizontal .rangeslider__handle:after{
    display:none;
}
.rangeslider-horizontal .rangeslider__handle{
    width: 25px;
    height: 25px;
    box-shadow: none;
    background-color: #ff0018;
    border-color: #ff0018;
}



/*
======================================
  * CSS TABLE CONTENT *  
======================================
1. HEADER
2. HEADER TOPBAR
3. HEADER LOGO
4. MENU BAR
5. MEGA MENU
6. HEADER TRANSPARENT
7. MENU ICON
8. PAGE BANNER
9. BLOG
10. TESTIMONIALS
11. COUNTERUP
12. FOOTER
13. INNER CONTENT CSS
======================================
  * END TABLE CONTENT *  
======================================
*/

.bg-fix {
    background-attachment: fixed;
	background-size: cover;
}
.parallax{
	background-size:cover;
}
.sticky-top {
	top:100px;
}
.header {
    position: relative;
    z-index: 99999;
}
.header ul,
.header ol {
    margin-bottom: 0;
}
.header .container-fluid{
	padding-left:30px;
	padding-right:30px;
}
/*without top bar*/
.secondary-menu {
    float: right;
    padding: 17px 0;
    position: relative;
    z-index: 9;
    height: 80px;
    border-left: 1px solid #cccccc;
    margin-left: 10px;	
}
.secondary-menu .btn-link{
	font-size: 22px;
    padding: 0;
    line-height: 22px;
    padding-top: 5px;
    outline: none !important;
	border: 0;
}
.secondary-menu .btn-link:hover{
	color:var(--primary);
	outline: none !important;	
}
.secondary-menu .secondary-inner {
    display: inline-block;
}
.nav-search-bar {
    background-color:rgba(255,255,255,0.95);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
	height:100%;
    z-index: 999;
    display: none;
    overflow: hidden;
	padding: 0 15px;
	transition: all 0.5s;
}
.nav-search-bar.show{
	display: block;
}
.nav-search-bar.show form {
	transition:all 0.5s ease 0.5s;
	-moz-transition:all 0.5s ease 0.5s;
	-webkit-transition:all 0.5s ease 0.5s;
	opacity:1;
}
.nav-search-bar form {
    width: 100%;
	max-width: 700px;
    margin: auto;
    position: relative;
	top: 50%;
	transition:all 0.5s;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
    -moz-transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    -webkit-transform: translate(0px, -50%);
	opacity:0;
}
.nav-search-bar .form-control {
	padding: 15px 60px 15px 15px;
    width: 100%;
    height: 70px;
    border: none;
    background: none;
    color: #000;
    font-size: 20px;
	border-bottom:2px solid #000;
}
.nav-search-bar .form-control::-moz-placeholder {
    color: #000;
}
.nav-search-bar .form-control:-moz-placeholder {
    color: #000;
}
.nav-search-bar .form-control:-ms-input-placeholder {
    color: #000;
}
.nav-search-bar .form-control::-webkit-input-placeholder {
    color: #000;
}
.nav-search-bar form span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer;
	color: #000;
}
.nav-search-bar > span {
	position: absolute;
	right: 15px;
	top: 15px;
	height: 25px;
	font-size: 20px;
	cursor: pointer;
	color: #000;
}
.header-lang-bx .dropdown-menu{
	top: 10px !important;
	right: 0;
	left: auto !important;
}
.header-lang-bx ul li{
	margin:0 !important;
}
.header-lang-bx .flag:after{
	content: "";
	width: 20px;
	height: 12px;
	display: inline-block;
	background-size: cover;
	background-position: center;
	margin-top: 0px;
	margin-right: 2px;
}

/* Cart Button */
.cart-btn{
    width: 27px;
    height: 34px;
    border: 2px solid #232323;
    display: inline-block;
    font-size: 12px;
    position: relative;
    padding: 11px 0 0 0;
    text-align: center;
}
.cart-btn:after{
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    border: 2px solid #232323;
    -moz-border: 2px solid #232323;
    -webkit-border: 2px solid #232323;
    -ms-border: 2px solid #232323;
    -o-border: 2px solid #232323;
    border-radius: 0px 0px 12px 12px;
    -moz-border-radius: 0px 0px 12px 12px;
    -webkit-border-radius: 0px 0px 12px 12px;
    -ms-border-radius: 0px 0px 12px 12px;
    -o-border-radius: 0px 0px 12px 12px;
    border-width: 0px 2px 2px 2px;
    top: 4px;
    left: 6px;
}
@media only screen and (max-width: 767px) {

}
@media only screen and (max-width: 480px) {
    .secondary-menu {
        margin: 0 1px 0 0;
        text-align: right;
    }
}


/*=================================
	2. HEADER TOPBAR
=================================*/
.top-bar {
    background-color: #232323;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    color: #fff;
    padding: 11px 0;
}
.topbar-left {
    float: left;
}
.topbar-right {
    float: right;
}
.topbar-center,
.topbar-left,
.topbar-right {
    padding-left: 15px;
    padding-right: 15px;
}
.topbar-left ul li,
.topbar-right ul li{
	display:inline-block;
	position:relative;
}
.topbar-left ul li a,
.topbar-right ul li a{
	color:#c3cbdd;
	font-size: 15px;
    display: flex;
    align-items: center;	
}
.topbar-left ul,
.topbar-right ul{
	margin:0;
	padding:0;
	list-style:none;
	font-size:14px;
}
.topbar-left ul li{
	padding-right:10px;
	margin-right:10px;
}
.topbar-right ul li{
	padding-left:10px;
	margin-left:10px;
}
.topbar-left ul li i{
	margin-right:5px;
	font-size: 22px;
	color: var(--primary);
}
.topbar-left ul li:after,
.topbar-right ul li:after{
	position: absolute;
    width: 1px;
    height: 20px;
    background-color: #000;
    right: 0;
    content: "";
    top: 2px;
    opacity: 0.1;
}
.topbar-right ul li:after{
	right:auto;
	left:0;
}
.topbar-right ul li:first-child:after,
.topbar-left ul li:last-child:after{
	content:none;
}
.header-lang-bx .btn:focus,
.header-lang-bx .btn:hover,
.header-lang-bx .btn{
	border:0 !important;
}
.header-lang-bx .btn{
	padding:0 10px 0px 0 !important;
	height: 18px;
	color:#000;
	font-size: 14px;
	font-weight: 400;
    text-transform: capitalize;
}
.header-lang-bx .btn:hover{
	color:#000;
}
.header-lang-bx.bootstrap-select.btn-group .dropdown-toggle .caret{
	right:0;
	top:5px;
}
.header-lang-bx .btn .fa-caret-down:before{
	content:"\f107";
}
@media only screen and (max-width: 991px) {
	.topbar-left{
		margin-bottom:5px;
	}
	.topbar-right,
	.topbar-left{
		padding-left: 0;
		padding-right: 0;
		width:100%;
		text-align:center;
	}
	.topbar-right ul li,
	.topbar-left ul li{
		margin:0;
		padding: 0px 5px;
	}
}
@media only screen and (max-width: 767px) {
    .top-bar [class*="col-"] {
        width: 100%;
        text-align: right;
        padding: 10px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .top-bar [class*="col-"]:last-child {
        border-bottom: none;
    }
	.top-bar {
		padding:5px 0;
		display: none;
	}
	.topbar-center,
	.topbar-left,
	.topbar-right {
		display:black;
		width:100%;
		text-align:center;
		padding:3px 15px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.topbar-left ul li{
		padding:0 5px;
	}
}
/*=================================
	3. HEADER LOGO
=================================*/
.menu-logo {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #0a4022;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 160px;
    height: 80px;
    position: relative;
    z-index: 9;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.menu-logo > a{
    display: table-cell;
    vertical-align: middle;
}
.menu-logo img{
  
}
.menu-logo span{
    font-size: 20px;
    color: #0a4022;
    letter-spacing: 20px;
}
.sticky-header.active .menu-logo{
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.sticky-header.active .logo-change .logo1,
.logo-change .logo2{
	display:none;
}
.sticky-header.active .logo-change .logo2{
	display: block;
}
@media only screen and (max-width: 767px) {
    .menu-logo,
	.sticky-header.active .menu-logo{
        width: 100px;
		max-width: 100px;
		height:50px;
    }
	.menu-logo img{
		max-width: 100px;
	}
	.header .navbar-toggler,
	.header .sticky-header.active .navbar-toggler{
		 margin: 14px 0 14px 15px;
	}
	.header .secondary-menu{
		padding: 7px 0;
		height:50px;
	}
	.btn, 
	.btn-secondry {
		font-size: 14px;
		padding: 9px 15px;
	}
	.secondary-menu .btn-link{
		padding-top: 7px;
	}
}
/*=================================
	4. MENU BAR
=================================*/
.menu-bar {
    background: #FFFFFF;
    width: 100%;
    position: relative;
}
.menu-bar .container{
	position: relative;
}
.navbar-toggler {
    border: 0 solid #0a4022;
    font-size: 16px;
    line-height: 24px;
    margin:28px 0 30px 15px;
    padding: 0;
	float:right;
}
.navbar-toggler span {
    background: #000;
}
.menu-links {
    padding: 0;
}
.menu-links .nav {
    float: right;
}
.menu-links .nav i {
    font-size: 9px;
    margin-left: 3px;
    margin-top: -3px;
    vertical-align: middle;
}
.menu-links .nav > li {
    margin: 0px;
    font-weight: 500;
    position: relative;
}
.menu-links .nav > li > a {
    border-radius: 0px;
    color: #232323;
    font-size: 17px;
    padding: 28px 10px 27px 10px;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    position: relative;
}
.menu-links .nav > li > a:hover {
    background-color: transparent;
    color: #FFC04B;
}
.menu-links .nav > li > a:active,
.menu-links .nav > li > a:focus {
    background-color: transparent;
}
.menu-links .nav > li.active > a,
.menu-links .nav > li.current-menu-item > a {
    background-color: transparent;
    color: #FFC04B;
}
.menu-links .nav > li:hover > a {
    color: #FFC04B;
}

.menu-links .nav > li:hover > a:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #fff;
    top: 95%;
    position: absolute;
    left: 30px;
    transform: rotate(45deg);
    z-index: 11;
}
.menu-links .nav > li.no-sub:hover > a:after {
	content:none;
}
.menu-links .nav > li:hover > .sub-menu,
.menu-links .nav > li:hover > .mega-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li > .sub-menu,
.menu-links .nav > li > .mega-menu {
	box-shadow:0 0 40px rgba(0, 0, 0, 0.2);
}
.menu-links .nav > li .sub-menu {
    background-color: #ffffff;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 15px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10;
	border-radius: 0;
	top: 100%;
}
.menu-links .nav > li .sub-menu li {
    position: relative;
}
.menu-links .nav > li .sub-menu li a {
	color: #585e6d;
    display: block;
    font-size: 15px;
    padding: 8px 25px;
    text-transform: capitalize;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    position: relative;
    font-weight: 500;
}
.menu-links .nav > li .mega-menu li a span,
.menu-links .nav > li .sub-menu li a span{
	position:relative;
}
.menu-links .nav > li .mega-menu li a span:after,
.menu-links .nav > li .sub-menu li a span:after{
	content: "";
    position: absolute;
    height: 1px;
    width: 0;
    background: var(--primary);
    bottom: 0;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	-moz-transform-origin:right;
	-webkit-transform-origin:right;
	-ms-transform-origin:right;
	-o-transform-origin:right;
	transform-origin:right;
}
.menu-links .nav > li .mega-menu li:hover > a span:after,
.menu-links .nav > li .sub-menu li:hover > a span:after{
	width:100%;
	-moz-transform-origin:left;
	-webkit-transform-origin:left;
	-ms-transform-origin:left;
	-o-transform-origin:left;
	transform-origin:left;
	left:0;
	right:auto;
}
.menu-links .nav > li .sub-menu li:hover a {
    text-decoration: none;
}
.menu-links .nav > li .sub-menu li:hover > a {
     color: var(--primary);
}
.menu-links .nav > li .sub-menu li:last-child {
    border-bottom: 0px;
}
.menu-links .nav > li .sub-menu li > .sub-menu.left,
.menu-links .nav > li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 220px;
}
.menu-links .nav > li > .sub-menu.left{
	left: auto;
    right: 0;
}
.menu-links .nav > li .sub-menu li .fa {
    color: inherit;
    display: block;
    float: right;
    font-size: 15px;
    position: absolute;
    right: 25px;
    top: 12px;
	opacity: 1;
}
.menu-links .nav > li .sub-menu li .fa.fa-nav {
	color: inherit;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-right: 5px;
    opacity: 1;
    position: unset;
    right: 10px;
    top: 12px;
}
.menu-links .nav > li .sub-menu li > .sub-menu {
    left: 220px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu {
    left: 220px;
    margin: 0px;
    opacity: 1;
    top: -1px;
    visibility: visible;
}
.menu-links .nav > li .sub-menu li:hover > .sub-menu:before {
    background-color: transparent;
    bottom: 0px;
    content: '';
    display: block;
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0px;
    width: 6px;
}

/*=================================
	5. MEGA MENU
=================================*/
.menu-links .nav > li.has-mega-menu {
    position: inherit;
}
.menu-links .nav > li .mega-menu {
    background-color: #ffffff;
    display: table;
    left: 0px;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
	z-index: 9;
	    padding: 0;
}
.menu-links .nav > li .mega-menu > li {
    display: table-cell;
    padding: 30px 0 25px;
    position: relative;
    vertical-align: top;
    width: 25%;
}
.menu-links .nav > li .mega-menu > li:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 1px;
    height: 100%;
}
.menu-links .nav > li .mega-menu > li:last-child:after {
    display: none;
}
.menu-links .nav > li .mega-menu > li > a {
	color: #000;
    display: block;
    padding: 0 25px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.menu-links .nav > li .mega-menu > li ul {
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    width: 100%;
}
.menu-links .nav > li .mega-menu > li ul a {
    color: #585e6d;
    display: block;
    font-size: 15px;
    line-height: 34px;
    text-transform: capitalize;
    padding: 0 25px;
}
.menu-links .nav > li .mega-menu > li ul a:hover {
    color: #EFBB20;
}
.menu-links .nav .mega-menu a i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}
.menu-links .nav > li.menu-item-has-children:before {
    content: "\f078";
    display: block;
    font-family: "FontAwesome";
    right: 4px;
    position: absolute;
    top: 50%;
    color: #999;
    margin-top: -8px;
    font-size: 8px;
}
/* Menu */
.menu-links .nav > li.add-mega-menu .mega-menu,
.has-mega-menu.add-mega-menu .mega-menu{
	display: block;
	padding: 20px;
	width: 1170px;
	max-width: 1170px;
	margin: auto;
}
.menu-links .nav > li .add-menu{
    display: flex;
	width:420px;
	left:-60px;
}
.add-menu-left{
    width: 100%;
	padding-left: 25px;
	border-bottom:0 !important;
}
.add-menu-left ul{
	padding:0;
}

.add-menu-right{
    min-width: 240px;
    width: 240px;
    padding: 10px 20px;
}
.menu-links .nav > li.has-mega-menu.demos .mega-menu{
	left:auto;
	right:auto;
	max-width: 600px;
	z-index: 9;
}
.menu-links .nav .add-menu-left ul li{
	list-style:none;
}
.menu-links .nav .add-menu-left ul li a{
	padding: 8px 0px;
}
.menu-links .nav .add-menu-left ul li a:hover{
	background-color:rgba(0,0,0,0);
}
.menu-adv-title{
	font-size: 16px;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    position: relative;
}
.menu-adv-title:after{
	content: "";
	width: 25px;
	height: 2px;
	display: block;
	background: #000;
	position: absolute;
	bottom: 0;
	left: 0;
	
}
.menu-links .menu-logo,
.menu-links .nav-social-link{
	display:none;
}
.header .menu-links .nav > li.dashboard .sub-menu{

}
.header .menu-links .nav > li.dashboard a{
	
}
.header .menu-links .nav > li.dashboard a .icon {
	padding: 0;
    font-size: 18px;
    margin-right: 15px;
    color: var(--primary);
    text-shadow: -4px 10px 15px rgba(46,36,255,0.3);
    margin-left: 0;
    float: none;	
}
/* Header Extra Nav */
.secondary-inner > ul{
	list-style:none;
	padding:0;
	display: flex;
    align-items: center;
	margin:0;
}
.secondary-inner ul li{
	display:inline-block;
	position:relative;
	margin-left: 20px;
}
.secondary-inner i{
	margin:0;
}
.search-btn:after{
	left:0;
	right:auto;
	top: 5px;
}
/* Nav  Badge */
/* Nav  Badge */
.shop-cart.navbar-right{
	margin-right:0;
	float:none;
	display: inline-block;
}
.shop-cart .dropdown-menu.cart-list{
	box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
	top: 50px;
	border:0;
}
.shop-cart .badge{
	font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
}
.shop-cart  li{
	padding: 10px 20px;
	border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
	
}
.shop-cart  li:last-child{
	border-bottom:0;
}
.shop-cart .media-left a {
    display: block;
    width: 60px;
}
.shop-cart .site-button-link{
	position:relative;
}
.cart-list.dropdown-menu .title {
	color: #232323;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 22px;
}
.cart-list.dropdown-menu{
	width: 300px;
    margin-top: 23px;
    right: 0;
    left: auto;
    border: 0;
    box-shadow: 0px 30px 60px 0 rgba(0,0,0,0.1);
}
.cart-list.dropdown-menu li{
	width:100%;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding: 15px 20px;
	margin: 0;
}
.cart-list.dropdown-menu li.cart-item .media .media-left {
    border: 1px solid rgba(0,0,0,0.05);
    padding: 5px;
}
.cart-list .media-object{
	width:50px;
}
.cart-item .media-body .price{
    color: var(--primary);
    font-weight: 700;
}
.cart-item .media-body span{
	font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 10px;
}
.cart-item .media-body{
	position:relative;
	padding-left: 15px;
	padding-right: 25px;
}
.item-close {
    color: #e86161;
	cursor:pointer;
}
.cart-list.dropdown-menu li.cart-item.subtotal .title span {
    float: right;
}
.cart-list.dropdown-menu li.cart-item .item-close {
	color: red;
    font-weight: 300;
    position: absolute;
    line-height: 24px;
    right: 0;
    top: 0;
    margin-right: 0;
    font-size: 35px;
}
@media only screen and (max-width: 1200px) {
	.secondary-inner ul li{
		margin-left: 15px;
	}
	.menu-links .nav > li > a{
		padding: 28px 5px;
		font-size: 16px;	
	}
}
@media only screen and (max-width: 767px) {
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.header .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}
	.cart-list.dropdown-menu{
		margin-top: 6px;
	}
}
@media only screen and (max-width: 991px) {
	[class*="ovbl-"] .counter-style-1 .counter-text, 
	.header-transparent .menu-links .nav > li:hover > a, 
	.header-transparent .menu-links .nav > li.active > a {
		color:#000 !important;
	}
	.add-menu-left ul{
		display: block !important;
	}
	.menu-links .menu-logo,
	.menu-links .nav-social-link{
		display:block;
	}
	.menu-links .nav-social-link{
		margin-top: auto;
		text-align: center;
		width: 100%;
		padding: 10px 0;
		background: #fff;	
	}
	.menu-links .nav-social-link a{
		color:#000;
		padding:5px 10px;
	}
	.menu-links .nav > li.has-mega-menu.demos .mega-menu{
		max-width:100%;
	}
	.menu-links .nav > li .mega-menu{
		border:0;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:10px 0;
	}
	.menu-links .nav > li .sub-menu,
	.menu-links .nav > li .mega-menu {
		border-radius:0;
		border-width: 1px 0 1px 0;
	}
    .menu-links .nav i {
        margin-top: 6px;
		float: right;
    }
    .menu-links {
        clear: both;
        margin: 0 -15px;
        border-bottom: 1px solid #E9E9E9;
    }
    .menu-links .nav {
        float: none;
        background: #fff;
		width:100%;
		display: block;
		margin-bottom: auto;
    }
    .menu-links .nav li {
        float: none;
		display:block;
		width:100%;
    }
    .menu-links .nav > li .sub-menu > li,
    .menu-links .nav > li .mega-menu > li {
        float: none;
        display: block;
        width: auto;
    }
    .menu-links .nav > li > a {
        padding: 10px 15px;
        border-top: 1px dashed #E9E9E9;
		display:block;
    }
    .menu-links .nav > li > a:hover,
    .menu-links .nav > li > a:active,
    .menu-links .nav > li > a:focus {
        background-color: #f0f0f0;
        text-decoration: none;
    }
    .menu-links .nav > li .mega-menu > li:after {
        display: none;
    }
    .menu-links .nav > li ul,
    .menu-links .nav > li .sub-menu,
    .menu-links .nav > li .mega-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: transparent;
    }
    .menu-links .nav > li ul.mega-menu ul {
        display: none;
    }
    .menu-links .nav > li:hover > ul,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu,
    .menu-links .nav > li .sub-menu li > .sub-menu {
        opacity: 1;
        visibility: visible;
        display: block;
        margin: 0;
    }
    .menu-links .nav > li ul.mega-menu li:hover ul {
        display: block;
    }
    .side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
    .nav.navbar-nav li a i.fa-chevron-down:before {
		content:"\f078";
	}
	.side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before,
	.nav.navbar-nav li.open a i.fa-chevron-down:before {
		content:"\f054";
	}
	.menu-links .nav > li .sub-menu li i.fa-angle-right:before{
		content: "\f078";
		font-size: 10px;
		position: absolute;
		z-index: 2;
		color: #000;
		right: 20px;
		top: -5px;
	}
	.menu-links .nav > li .sub-menu li.open i.fa-angle-right:before{
		content: "\f054";
	}
    .menu-links .nav > li .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu .sub-menu,
    .menu-links .nav > li:hover .sub-menu,
    .menu-links .nav > li:hover .mega-menu {
        display: none;
        opacity: 1;
        margin-top: 0;
    }
	.menu-links .nav li .sub-menu .sub-menu{
		display: none;
        opacity: 1;
        margin-top: 0;
	}
	.menu-links .nav > li.open > .sub-menu .sub-menu{
		display: none;
	}
	.menu-links .nav > li.open > .sub-menu li.open .sub-menu,
    .menu-links .nav > li.open > .mega-menu,
    .menu-links .nav > li.open > .sub-menu,
	.menu-links .nav > li ul.mega-menu ul{
        display: block;
        opacity: 1;
        margin-top: 0;
		box-shadow: none;
    }
	.menu-links .nav > li:hover > a:after{
		content:none;
	}
	.menu-links .nav > li .sub-menu li .fa{
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		margin: 0;
		right: -1px;
		color:#000;
	}
	.menu-links .nav > li .mega-menu > li{
		padding:0;
	}
	.menu-links .nav > li .mega-menu > li > a{
		display:none;
	}
	.menu-links .nav .mega-menu a i{
		display:inline-block;
		float:none;
		margin-top: 0;
	}
    .menu-links .nav .open > a,
    .menu-links .nav .open > a:focus,
    .menu-links .nav .open > a:hover {
        background-color: inherit;
        border-color: #e9e9e9;
    }
	.menu-links .nav > li .sub-menu li a{
		padding: 8px 15px;
	}
	.add-menu-left{
		padding-left: 0;
	}
	.add-menu-right{
		padding: 10px 15px;
	}
	.menu-links .nav > li .mega-menu > li ul a{
		padding: 2px 15px;
	}
	.style-3 .navbar-toggler span{
		background:#fff;
	}
	.shop-cart .dropdown-menu.cart-list{
		left:auto;
		right:0;
	}
	.rs-nav .menu-links.nav-dark{
		background-color:#202020;
	}
	.rs-nav .menu-links{
		position: fixed;
		width: 60px;
		left:-280px;
		height:100vh !important;
		transition:all 0.5s;
		-webkit-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-o-transition:all 0.5s;
		top:0;
		background-color:#fff;
		margin:0;
		z-index:99;
		overflow-y: scroll;
		flex-direction: column;
	}
	.rs-nav .menu-links li.open a{
		position:relative;
	}
	.navbar-nav{
		height:auto;
	}
	.rs-nav .menu-links.show {
		left: -1px;
		transition:all 0.8s;
		-webkit-transition:all 0.8s;
		-moz-transition:all 0.8s;
		-o-transition:all 0.8s;
		margin:0;
		width: 100%;
		width: 300px;
		padding: 15px 15px 5px 15px;
	}
	.rs-nav .sticky-header.active .menu-links .nav{
		height:auto;
	}
	.rs-nav .navbar-toggler.open:after{
		background-color: rgba(0, 0, 0, 0.6);
		content: "";
		height: 100%;
		left: 0;
		position: fixed;
		right: 0px;
		top: -20px;
		transform: scale(100);
		-o-transform: scale(100);
		-moz-transform: scale(100);
		-webkit-transform: scale(100);
		width: 100%;
		z-index: -1;
		transition: all 0.5s;
		transform-origin: top right;
		margin: 0 0px 0px 10px;
		box-shadow: 0 0 0 500px rgba(0,0,0,0.6);
	}
	.rs-nav .menu-links .menu-logo {
		display: block;
		float: none;
		height: auto;
		max-width: 100%;
		padding: 10px 15px;
		width: 100%;
		text-align:center;
	}
	.rs-nav .menu-links .menu-logo img{
		max-width: unset;
		width: 130px;
		vertical-align: middle;
	}
	.rs-nav .menu-links .menu-logo a{
		display:inline-block;
	}
	.rs-nav .navbar-toggler.open span{
		background:#fff;
	}
	.menu-links .nav > li > .mega-menu,
	.menu-links .nav > li > .sub-menu{
		border-top: 1px dashed rgba(0,0,0,0.1);
		border-top: 1px dashed rgba(0,0,0,0.1);
		
	}
	.menu-links .nav > li .mega-menu{
		padding: 15px 0;
	}
}
.sticky-no .menu-bar {
    position: static !important;
}
.sticky-header.active .menu-bar {
    position: fixed;
    top: 0;
    left: 0;
	box-shadow:0 0 50px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 991px) {
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .sticky-header.active .menu-links .nav {
        height: 225px;
    }
    .sticky-header.active .menu-links .nav > li > a {
        padding: 10px 15px;
    }
}

/*=================================
	6. HEADER TRANSPARENT
=================================*/
.header-transparent{
	position:absolute;
	width:100%;
}
.header-transparent .menu-bar{
	background-color:rgba(0,0,0,0);
	border-bottom: 1px solid rgba(255,255,255,0.1);
}
.header-transparent .menu-links .nav > li > a{
	color:#fff;
}
.header-transparent .menu-links .nav > li > a,
.header-transparent .secondary-menu .btn-link,
.header-transparent .navbar-toggler{
    color: #fff;
}
.header-transparent .secondary-menu .btn-link:hover{
	color:var(--primary);
}
.header-transparent .navbar-toggler span{
    background-color: #fff;
}
.header-transparent .sticky-header.active .menu-bar{
	position:fixed;
	background-color:rgba(0,0,0,0.9);
}
.header-transparent .secondary-menu{
	border-left: 1px solid rgba(255, 255, 255, 0.08);
}
.header-transparent .top-bar{
	background-color: rgba(0,0,0,0);
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}
.header-transparent .search-btn:after,
.header-transparent .topbar-left ul li:after,
.header-transparent .topbar-right ul li:after{
	background-color:#fff;
}
.header-transparent .cart-btn:after,
.header-transparent .cart-btn{
	border-color:#fff;
}
.fullwidth .container-fluid{
	padding-left: 30px;
    padding-right: 30px;
}
.onepage .navbar{
	margin-bottom:0;
	border:0;
}
.header-transparent .header-lang-bx .btn{
	background-color: transparent !important;
	color:#fff;	
} 
.header-transparent .topbar-right .header-lang-bx ul li a{
	color:#000;	
}
.header-transparent .topbar-left ul li a, 
.header-transparent .topbar-right ul li a{
	color:#fff;	
}

/* Style 3 */
.style-3 .menu-bar{
	background-color:#0a4022;
}
.aside-btn{
	width: 30px;
    height: 22px;
    position: relative;
    display: block;
}
.aside-btn span{
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background:#fff;
  opacity: 1;
  left: 0;
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.aside-btn span:nth-child(1) {
	top: 0px;
}
.aside-btn span:nth-child(2) {
	top: 50%;
	transform:translateY(-50%)
}
.aside-btn span:nth-child(3) {
	bottom: 0;
}
.secondary-inner ul li{
	
}
.aside-box{
	
}
.style-3 .secondary-menu{
	padding: 0;
    margin-left: 0;
    border: 0;
}
@media only screen and (min-width: 991px) {
	.style-3 .menu-links .nav > li.active > a,
	.style-3 .menu-links .nav > li:hover > a,
	.style-3 .menu-links .nav > li > a{
		color:#fff;
	}
}
.search-box form{
    height: 80px;
    position: relative;
    background: rgba(0,0,0,0.15);
}
.search-box form button{
	border: 0;
    background: transparent;
    color: #fff;
    position: absolute;
    top: 28px;
    font-size: 20px;
    padding: 0 0 0 20px;
    left: 0;	
}
.search-box form .form-control{
    height: 80px;
    border: 0;
    background: transparent;
    color: #fff;
    padding: 4px 15px 0px 50px;
    font-size: 18px;
    width: 190px;
    font-weight: 400;
}
.search-box form .form-control::placeholder {
    color: #fff;
}
.search-box form .form-control::-moz-placeholder {
    color: #fff;
}
.search-box form .form-control::-ms-input-placeholder {
    color: #fff;
}
.search-box form.form-control::-webkit-input-placeholder {
    color: #fff;
}

.style-3 .cart-btn{
	color: #fff;
	border: 2px solid #fff;		
}
.style-3 .cart-btn:after{
    border: 2px solid #fff;
    -moz-border: 2px solid #fff;
    -webkit-border: 2px solid #fff;
    -ms-border: 2px solid #fff;
    -o-border: 2px solid #fff;
    border-width: 0px 2px 2px 2px;	
}
.style-3.box .menu-bar {
    background-color: transparent;
}
.style-3.box .menu-bar .container{
    background-color: #0a4022;
}
.style-3.box .secondary-inner > ul > li {
    margin-left: 15px;
	margin-right: 10px;
}
.style-3.box{
	 margin-bottom: 40px;
}
.style-3.box .sticky-header{
	position:absolute;
	width:100%;
	height:40px;
	z-index: 99999;	
}

/* Extra Info */
.header-extra-info{
    padding: 20px 0;
	width: 100%;	
}
.header-contant-info ul{
	margin:0;
	padding:0;
	list-style:none;
    display: flex;
    align-items: center;	
}
.header-contant-info ul li{
    display: inline-block;
    padding-left: 60px;
    position: relative;
    padding: 5px 20px 0 60px;
    margin-right: 20px;
}
.header-contant-info ul li:first-child{
	border-right: 1px solid rgba(0,0,0,0.2);
}
.header-contant-info ul li:last-child{
	padding:0;
	margin-right:0;
}
.header-contant-info ul li .icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-size: 20px;
    text-align: center;
    line-height: 48px;
    position: absolute;
    left: 0;
    top: 0;	
}
.header-contant-info ul li .title{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    line-height: 20px;
}
.header-contant-info ul li p{
    color: #6d7380;
    margin: 0;
    font-size: 15px;
}
.extra-logo{
	max-width:210px;
}
.extra-info-inner{
	display: flex;
    justify-content: space-between;
    align-items: center;	
}


@media only screen and (max-width: 991px) {
	.header-transparent .menu-links .nav > li > a{
		color:#000;
	}
	.header-transparent .menu-links.nav-dark .nav > li > a{
		color:#fff;
	}
	.style-3.box .navbar-toggler{
		margin: 28px 0px 30px 15px;
	}
	.style-3.box .sticky-header{
		position: unset;
		width: 100%;
		height: auto;
		background-color: #0a4022;
	}
	.style-3.box {
		margin-bottom: 0;
	}
	.style-2 .sticky-header {
		position: relative;
	}
	.style-2 .menu-bar {
		background:  var(--primary);
	}
	 .style-2 .menu-bar:before{
		height:100%;
		width:25%;
		position:absolute;
		top:0;
		right:0;
		content:"";
		background:#fff;
	}
	.style-2 .secondary-menu {
		padding: 17px 0px 17px 0;
	}
}
@media only screen and (max-width: 767px) {
	.style-2 .secondary-menu{
		padding: 7px 0px 7px 0;
	}
	.fullwidth .container-fluid{
		padding-left: 15px;
		padding-right: 15px;
	}
	.search-box form .form-control {
		height: 50px;
	    font-size: 14px;	
		width: 120px;
	    padding: 0px 15px 0px 40px;		
	}
	.search-box form{
		height: 50px;
		font-size: 14px;	
	}
	.search-box form button	{
		top: 14px;
		font-size: 18px;
		padding: 0 0 0 15px;	
	}
	.style-3.box .secondary-inner ul li {
		margin-left: 10px;
		margin-right: 0;
	}
	.style-3.box .navbar-toggler {
		margin: 14px 0px 14px 15px;
	}

}

/*=================================
	7. MENU ICON
=================================*/
.menuicon{
    width: 22px;
    height: 22px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 99;
}
.menuicon span{
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.menuicon span:nth-child(1) {
  top: 0px;
}
.menuicon span:nth-child(2) {
  top: 50%;
  transform:translateY(-50%)
}
.menuicon span:nth-child(3) {
  bottom: 0;
}
.menuicon.open span:nth-child(1) {
  top: 50%;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.menuicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.menuicon.open span:nth-child(3) {
  top: 50%;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.menuicon i{
	position:relative;
	width: 14px;
	display: inline-block;
	height: 12px;
	margin-left:5px;
}
.menuicon.open i{
	height:12px;
}
.menuicon.open i span:nth-child(1),
.menuicon.open i span:nth-child(3) {
	top:5px;
}
.header-transparent .extmenu a{
	color:#fff;
	text-transform: uppercase;
	font-weight: 600;
}
.extmenu a span{
	background:#fff;
}
/*========================
	8. PAGE BANNER
=========================*/
.page-banner {
    height: 320px;
    background-size: cover;
    background-position: center center;
    display: table;
    width: 100%;
	text-align:left;
	background-color: #f7f7f7;
}

.page-banner .img{
    height: 320px;
  
}
.page-banner.bg-gray {
	background-color:#eef2f8;
}
.page-banner-sm {
    height: 220px;  
}
.page-banner-lg {
    height: 500px;  
}
.page-banner .container {
    display: table;
    height: 100%;
}
.page-banner-entry {
    display: table-cell;
    vertical-align: middle;
	text-align: center;
}
.page-banner-entry.align-m {
    vertical-align: middle;
}
.page-banner h1 {
	font-size: 45px;
    margin-bottom: 10px;
    line-height: 55px;
    font-weight: 500;
    color: #232323;
}
.page-banner h1.text-white{
	color: #fff;
}
.breadcrumb-row ul li a{
	color:var(--sc-primary);
}
.banner-sm-title {
    color: var(--sc-primary);
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}
.breadcrumb-row.text-black ul li{
    color: #686767;
    font-weight: 500;
    font-size: 16px;
}
.breadcrumb-row.text-black ul li a{
	color:var(--primary);
}
@media only screen and (min-width: 991px) {
	.header.style-2 + .page-content .page-banner{
		padding-top: 80px;
	}
}
@media only screen and (max-width: 767px) {
	.page-banner-entry{
		vertical-align: middle;
	}
	.page-banner{
		padding-bottom: 0;
		height: 200px;
	}
	.page-banner h1{
		line-height: 24px;
		font-size: 20px;
		text-align:center;
	}
}
/*========================
	9. BLOG
=========================*/
.blog-post{
    position: relative;
    margin-bottom: 50px;
}
.ttr-post-title {
    margin-bottom: 5px;
}
.ttr-post-title .post-title {
    margin-bottom: 5px;
}
.ttr-post-meta {
    margin-bottom: 15px;
}
.ttr-post-meta ul {
    list-style: none;
	text-transform:capitalize;
	margin: 0;
    padding: 0;
}
.ttr-post-meta ul li {
    padding: 0;
    display: inline-block;
    color: #25314a;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}
.ttr-post-meta ul li strong{
	font-weight: 500;
}
.ttr-post-meta li:after {
    content: "|";
    display: inline-block;
    font-weight: normal;
    margin-left: 5px;
    opacity: 0.5;
}
.ttr-post-meta li:last-child:after {
    display: none;
}
.ttr-post-meta a {
    color:var(--primary);
}
.ttr-post-meta i {
    margin: 0 5px;
	font-size: 15px;
}
.ttr-post-text {
    margin-bottom: 20px;
}
.ttr-post-text p {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 1.5;
}
.ttr-post-text p:last-child {
    margin: 0;
}
.blog-group .blog-post{
	margin-bottom:30px;
}
.blog-group .blog-post{
	display: flex;
}
.blog-group .blog-post .ttr-post-info{
	padding: 30px;
}
.blog-group .blog-md .ttr-post-media {
    width: 350px;
    max-width: 350px;
    min-width: 350px;
}
.blog-group .blog-md .ttr-post-media img {
    height: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 1200px) {
	.ttr-post-meta .d-flex{
		display:block !important;
	}
}
/*blog post half iamge*/

.blog-md .ttr-post-media {
    width: 350px;
	border-radius: 4px;
}
.blog-md.sm-large,
.blog-md.md-large{
	display: flex;
    flex-wrap: wrap;
}
.blog-md.md-large{
	margin-bottom:60px;
}
.blog-md.md-large .ttr-post-media {
	width:55%;
	flex: 0 0 55%;
}
.blog-md.md-large .ttr-post-info{
	width:45%;
	flex: 0 0 45%;
	display: flex;
    flex-direction: column;
	padding: 30px 0 30px 30px;
}
.blog-md.md-large .post-title{
	font-size:40px;
	line-height:50px;
}
.blog-md.sm-large .media-post li a,
.blog-md.md-large .media-post li a {
    color: #a3b1c7;
    vertical-align: middle;
    font-size: 18px;
    font-weight: 600;
}
.blog-md.sm-large .media-post li a{
	font-size: 14px;
}
.blog-md.md-large p{
    font-size: 18px;
    color: #585a86;
	line-height: 32px;
}
.blog-md.sm-large .media-post,
.blog-md.md-large .media-post{
	border:0;
	padding: 0;
    margin-bottom: 10px;
}
.blog-md.md-large .ttr-post-info .post-title{
	margin-bottom: 10px;
}
.blog-md.sm-large .ttr-post-info  .post-extra,
.blog-md.md-large .ttr-post-info  .post-extra{
	border:0;
	margin-top:auto;
}

.blog-md.sm-large{
	margin-bottom:0;
}
.blog-md.sm-large .ttr-post-media {
	width:45%;
	flex: 0 0 45%;
	display: flex;
    align-items: stretch;
}
.blog-md.sm-large .ttr-post-info{
	width:55%;
	flex: 0 0 55%;
	display: flex;
    flex-direction: column;
	padding: 0 0 0 30px;
}
.blog-md.sm-large .post-title{
	font-size:22px;
	line-height:32px;
}

.blog-md .ttr-post-info {
    border: none;
	padding-left:30px;
}
.blog-md .ttr-post-tags {
    border: none;
    display: inline-block;
    padding: 0;
}
.blog-md .ttr-post-info .post-title{
	margin-top:0;
}
.blog-md, 
.blog-md .ttr-post-info {
    overflow: hidden;
}
.blog-md .ttr-post-info, 
.blog-md .ttr-post-media {
    display: table-cell;
    vertical-align: middle;
}
.blog-md .ttr-post-media {
	vertical-align: top;
}
.blog-md .ttr-post-info > div:last-child{
	margin-bottom:0;
}
/* blog-area */
.blog-area .heading-bx {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.side-post{
	border-left: 1px solid rgba(0,0,0,0.1);
}
.side-post .blog-post.blog-md{
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-bottom:30px;
	padding-bottom:30px;
	padding-left:30px;
}
.side-post .blog-post.blog-md:last-child{
	border-bottom:0;
	margin-bottom:0;
	padding-bottom:0;
}
.side-post .blog-md .ttr-post-info h4 {
    font-size: 24px;
}
.side-post .blog-md .ttr-post-info{
    padding-left: 20px;
}
.side-post .blog-md .ttr-post-media {
	width: 200px;
}

/* Blog Share */
.blog-share{
	position:relative
}
.share-btn{
	position:absolute;
	right:0;
	bottom:0;
}
.share-btn ul{
	margin:0;
	padding:0;
	list-style:none;
}
.share-btn ul li{
	display:inline-block;
	margin-left: -40px;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
}
.share-btn ul li a.btn{
	border-radius: 3px;
	width: 40px;
	line-height: 44px;
	height:44px;
	display: block;
	color:#000;
	background: #E6E6E6;
}
.share-btn ul li a.btn:hover{
	background:#D6D6D6;
}
.share-btn ul:hover li.share-button a.btn{
	background:#A0A0A0;
}	
.share-btn ul:hover li{
	margin-left: 4px;
}
.share-details-btn ul{
	margin:0;
	padding:0;
	list-style:none;
}
.share-details-btn ul li{
	display:inline-block;
	margin-right: 5px;
	margin-bottom: 6px;
}
/* recent news style 2 */
.recent-news{
	margin-bottom:50px;
	position: relative;
}
.blog-post .media-post li a,
.blog-single .media-post li a,
.recent-news .media-post li a{
	font-weight: 400;
    color: #696969;
    font-size: 15px;
    letter-spacing: unset;
    line-height: 22px;	
}
.blog-post .media-post,
.blog-single .media-post,
.recent-news .media-post{
	display: flex;
    align-items: center;
	border-bottom:0;
	padding:0;
	margin-bottom: 10px;
    list-style: none;	
}
.blog-single .post-title,
.blog-post .post-title,
.recent-news .post-title{
	font-weight:700;
}
.blog-post .post-title,
.recent-news .post-title{
	margin-bottom: 10px;
}
.recent-news .media-post li.date{
	padding:0;
	line-height: 14px;
}
.recent-news .media-post li{
	padding: 0 20px 0 0px;
	position:relative;
}
.recent-news .media-post li.admin a img{
	width: 36px;
    height: 36px;
	margin-right: 15px;
    border-radius: 36px;
}
.recent-news .media-post li.comment a{
	display: flex;
}
.blog-post .media-post li a i,
.recent-news .media-post li a i{
	margin-right:5px;
}
.blog-post .btn-link,
.recent-news .btn-link{
	color:var(--primary);
	font-size:15px;
	font-weight:600;
	border-bottom:1px solid;
	border-radius:0;
	text-decoration: none !important;	
}
.blog-post .ttr-post-info p,
.recent-news .info-bx p{
    font-size: 16px;
    line-height: 1.5;
    color: #8f8f8f;
    font-weight: 400;
    margin-bottom: 15px;
}
.blog-post .ttr-post-info p{
	margin-bottom:10px;
}
.blog-post .media-post{
	margin-bottom: 10px;
}
.recent-news .info-bx{
	position:unset;
	padding-top: 25px;
}
.recent-news .post-extra{
    padding: 0;
    border: 0;
	position: relative;
}
.recent-news .post-extra .btn{
	padding: 13px 30px;
}

.aside-bx{
    border-left: 1px solid rgba(0,0,0,0.1);
    padding-left: 30px;
}










@media only screen and (max-width: 767px) {
    .blog-md.blog-post .ttr-pfost-media,
    .blog-md.blog-post .ttr-post-info {
        float: none;
        margin: 0 0 20px;
        width: 100%;
		display:block;
		padding-left:0;
    }
	.blog-md.blog-post .ttr-post-info {
		margin-top:20px;
	}
	.blog-md .ttr-post-media {
		width:100%;
	}
}
/*Blog single*/
.blog-single{
    margin-bottom: 30px;
}
.blog-single .media-post{
	padding:0;
	
}
.blog-single .action-box{
	margin-bottom:30px;
}
.blog-single .ttr-post-text ul{
	padding: 0 0 0 30px;
}
.blog-single .ttr-post-text ul li{
    padding: 5px 0;
    font-size: 18px;
}
.blog-single .ttr-post-meta .post-tags a{
	color: var(--primary);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
}
.blog-single .ttr-post-meta {
    margin-bottom: 0;
}
.blog-single .ttr-post-text {
    margin-top: 10px;
}
.blog-single .ttr-post-tags {
    margin-top: 20px;
}
.blog-single .ttr-post-media{
	border-radius:4px;
}
/*= comment list = */
.comments-area {
    padding: 0;
}
.comments-area .comments-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}
ol.comment-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
ol.comment-list li.comment {
    position: relative;
    padding: 0;
}
ol.comment-list li.comment .comment-body {
	margin-bottom: 40px;
    margin-left: 80px;
    position: relative;
    background: #f6f7f8;
    padding: 30px 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
ol.comment-list li.comment .comment-body:after{
    content: "";
    background: #f6f7f8;
    border: 1px solid rgba(0,0,0,0.1);
    width: 12px;
    height: 12px;
    position: absolute;
    left: -6px;
    transform: rotate(45deg);
    border-width: 0 0px 1px 1px;
    top: 30px;
}
ol.comment-list li.comment .comment-author {
    display: block;
    margin-bottom: 0;
}
ol.comment-list li.comment .comment-author .avatar {
    position: absolute;
    top: 0;
    left: -80px;
    width: 70px;
    height: 70px;
    border-radius: 64px;
    -webkit-border-radius: 64px;
    background: #fff;
    padding: 4px;
    box-shadow: 0 0 30px 0 rgba(0,10,105,0.03);
}
ol.comment-list li.comment .comment-author .fn {
    display: inline-block;
    color: #272d3a;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 5px;
    line-height: 28px;
}
ol.comment-list li.comment .comment-author .says {
    display: none;
    color: #999999;
    font-weight: 600;
}
ol.comment-list li.comment .comment-meta {
    color: #8d8d8d;
    text-transform: uppercase;
    margin-bottom: 5px;
}
ol.comment-list li.comment .comment-meta a {
    color: #8d8d8d;
}
ol.comment-list li.comment .comment-meta a {
	color: var(--primary);
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: capitalize;
}
ol.comment-list li.comment p {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
ol.comment-list li.comment .reply a {
background: #232323;
    padding: 6px 15px;
    display: inline-block;
    border-radius: 0;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
}
ol.comment-list li.comment .reply a:hover {
	background-color: var(--primary);
	color:#fff;
}
ol.comment-list li .children {
    list-style: none;
    margin-left: 80px;
	padding: 0;
}
ol.comment-list li .children li {
    padding: 0;
}
@media only screen and (max-width: 767px) {
    .comments-area .padding-30 {
        padding: 15px;
    }
    ol.comment-list li.comment .comment-body {
        margin-bottom: 30px;
        margin-left: 70px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -75px;
        height: 60px;
        width: 60px;
    }
    ol.comment-list li .children {
        margin-left: 20px;
    }
    ol.comment-list li.comment .reply a {
        position: static;
    }
}
@media only screen and (max-width: 480px) {
    ol.comment-list li.comment .comment-body {
        margin-left: 52px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -55px;
        top: 12px;
        width: 40px;
        height: 40px;
    }
}
/*= comment form = */
.comment-respond {
    padding: 30px 30px;
    background: #f6f7f8;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
.comment-respond .comment-reply-title {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}
.comments-area .comment-form {
    margin: 0 -15px;
}
.comments-area .comment-form .comment-notes {
    display: none;
}
.comments-area .comment-form p {
    width: 33.333%;
    float: left;
    padding: 0 15px;
    margin-bottom: 30px;
    position: relative;
}
.comments-area .comment-form p.form-allowed-tags {
    width: 100%;
}
ol.comment-list li.comment .comment-respond .comment-form p {
    padding: 0 15px !important;
}
.comments-area .comment-form p label {
    display: none;
    line-height: 18px;
    margin-bottom: 10px;
}
.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
	width: 100%;
    height: 45px;
    padding: 14px 22px 14px 22px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    text-transform: capitalize;
    color: #000000;
    background: #fff;
    font-size: 14px;
}
.comments-area .comment-form p input[type="text"]:hover,
.comments-area .comment-form p textarea:hover,
.comments-area .comment-form p input[type="text"]:focus,
.comments-area .comment-form p textarea:focus{
	border-color:var(--primary);
	box-shadow:none !important;
	    outline: none;
}
.comments-area .comment-form p.comment-form-comment {
    width: 100%;
    display: block;
    clear: both;
}
.comments-area .comment-form p textarea {
    height: 120px;
}
.comments-area .comment-form p.form-submit {
    clear: both;
    float: none;
    width: 100%;
    margin: 0;
}
.comments-area .comment-form p input[type="submit"] {
    background-color: #EFBB20;
    border: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 30px;
}
.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
    background-color: var(--sc-primary);
    border-color: #6ab33e;
    color: #fff;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-form p {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
    .comment-respond {
        padding: 20px;
    }
}
/* Blog User */
.author-profile-pic {
    width: 125px;
    height: 125px;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: -2px 5px 10px 0 rgba(0,16,147,0.1);
    z-index: 2;
    position: relative;
	margin: auto;
}
.author-profile-content ul li a{
	padding: 0 2px;
    background: var(--primary);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    line-height: 40px;
	text-align:center;
}
.author-profile-content ul li a:hover{
	background: var(--sc-primary);
	color:#fff;
}
.author-profile-content ul li a i{
	margin:0;
}
.author-box{
    background-color: #f6f7f8;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.1);
}
.author-box.blog-user .author-profile-info{
	display: flex;
}
.blog-user .author-profile-pic img{
	width:100%;
}
.blog-user .author-profile-pic {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow:none;
    z-index: 2;
    position: relative;
	margin: 0 auto;
	border:0;
}
.blog-user .author-profile-content {
    padding-left: 20px;
	text-align:left;
	padding-top: 0;
	width: 100%;
}
.blog-user .author-profile-content h6{
	font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
}
.blog-user .author-profile-content p {
	margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
}
/* Gutenberg Css */
.min-container,
.blog-post.blog-single{
	max-width: 750px;
    margin-left: auto;
    margin-right: auto;
	padding-left: 15px;
    padding-right: 15px;
}
.max-container{
	max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
	padding-left: 55px;
    padding-right: 55px;
}
.blog-post.blog-single .ttr-post-info{
	padding:0;
}
.blog-post.blog-single .ttr-post-text.text > ol,
.blog-post.blog-single .ttr-post-text.text > ul {
    padding-left: 30px;
}
.blog-post.blog-single .ttr-post-text.text > ol li,
.blog-post.blog-single .ttr-post-text.text > ul li {
	padding: 5px 5px;
	color: #2b2d2e;
}
.blog-post.blog-single .ttr-post-text.text > .wp-block-gallery {
	padding: 0;
	list-style: none;
}
.blog-post.blog-single .ttr-post-text.text > .wp-block-gallery li{
	padding: 0;
	list-style: none;
	margin:10px;
}
.wp-block-gallery .blocks-gallery-image, 
.wp-block-gallery .blocks-gallery-item{
	margin: 0 8px 8px 0;
}
.wp-block-gallery.columns-4 .blocks-gallery-image, 
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image, 
.wp-block-gallery.columns-3 .blocks-gallery-item{
	margin-right: 8px;
}
.content-spacious-full .wp-block-gallery {
    margin-left: calc(-200px - (10px/2));
    margin-right: calc(-200px - (10px/2));
    width: calc(100% + 400px + 10px);
    max-width: initial;
}
.alignwide{
	margin-left: calc(-100px - (10px/2));
    margin-right: calc(-100px - (10px/2));
    width: calc(100% + 200px + 10px);
    max-width: initial;
}
.alignfullwide{
	margin-left: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
	width: 100vw;
	max-width: 100vw;
	box-sizing: border-box;
}
.ttr-post-text h1,
.thm-unit-test .ttr-post-text h1 {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 10px;
	font-weight: 700;
}
.ttr-post-text h2,
.thm-unit-test .ttr-post-text h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 5px;
	font-weight: 700;
}
.ttr-post-text h3,
.thm-unit-test .ttr-post-text h3 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 8px;
	font-weight: 700;
}
.ttr-post-text h4,
.thm-unit-test .ttr-post-text h4 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
	font-weight: 600;
}
.ttr-post-text h5,
.thm-unit-test .ttr-post-text h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
	font-weight: 600;
}
.ttr-post-text h6,
.thm-unit-test .ttr-post-text h6 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
	font-weight: 600;
}
pre {
	margin: 0 0 25px;
    padding: 20px;
    color: #ddd;
    background-color: #222;
    white-space: pre;
    text-shadow: 0 1px 0 #000;
    border-radius: 5px;
    border-bottom: 1px solid #555;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4) inset, 0 0 20px rgba(0, 0, 0, 0.2) inset;
}
.blog-post.blog-single .ttr-post-text ul li,
.blog-post.blog-single .ttr-post-text ol li,
.blog-post.blog-single .ttr-post-text p {
	font-size:15px;
	line-height:28px;
}
.blog-post.blog-single .ttr-post-text p {
	margin-bottom:28px;
}
.blog-post.blog-single .ttr-post-text blockquote p{
	font-size: 18px;
	line-height: 30px;
	color: #2b2d2e;
	margin-bottom: 10px;
	font-weight: 600;
	text-align: left;
}
.wp-block-table{
	
}
.blog-post.blog-single .ttr-post-text .wp-block-gallery {
    margin-left: calc(-190px - (10px/2));
    margin-right: calc(-190px - (10px/2));
    width: calc(100% + 380px + 10px);
    max-width: initial;
}
.blog-post.blog-single .ttr-post-text > div + p:first-letter,
.blog-post.blog-single .ttr-post-text > p:first-child:first-letter{
    color: #6d9ec4;
    font-size: 100px;
    float: left;
    margin-right: 20px;
    margin-bottom: 14px;
	margin-top: 15px;
}
.paginate-links {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.paginate-links a, 
.paginate-links > span {
    margin: 0 0 0 10px;
    position: relative;
    border: 0px solid #6cc000;
    color: #000;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    background: #E0E0E0;
    border-radius: 3px;
}
.wp-block-button__link {
    border-radius: 4px;
    font-size: 16px;
    padding: 10px 30px;
    font-weight: 700;
}
.blog-post.blog-single .ttr-post-text .has-large-font-size {
    font-size: 36px;
    line-height: 50px;
	color: #000;
}
/* Gutenberg Gallery */
.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0 !important;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 6px 6px 0;
    position: relative
}
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
    bottom: 0;
    color: #fff;
    font-size: 13px;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 5px;
    position: absolute;
    text-align: center;
    width: 100%
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 8px)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%
}

@media only screen and (max-width: 1024px) {
	.alignwide{
		margin-left: 0;
		margin-right:0;
		width: 100% !important;
		max-width: 100% !important;
	}
	.alignwide .alignleft img{
		width: 100%;
	}
	
	.alignwide .alignleft{
		margin: 5px 0px 25px 0;
		float: none;
	}
}
@media (min-width:600px) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(33.33333% - 10.66667px)
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(25% - 12px)
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(20% - 12.8px)
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(16.66667% - 13.33333px)
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(14.28571% - 13.71429px)
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 20px;
        width: calc(12.5% - 14px)
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}
.wp-block-image.alignfullwide img{
	border-radius:0;
}
.wp-block-image img{
	border-radius:10px;
}
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex
}
.aligncenter{
	text-align:center;
}
.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}
/*========================
	10. TESTIMONIALS
=========================*/
.testimonial-pic {
    background: #FFF;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #FFF;
}
.testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
    width: 100%;
    height: 100;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}
.testimonial-text {
    padding: 15px;
    position: relative;
	font-size:15px;
	font-weight: 400;
}
.testimonial-detail {
    padding: 5px;
}
.testimonial-name{
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}
.testimonial-position {
	font-size: 12px;
    font-style: inherit;
    text-transform: uppercase;
}
.testimonial-name,
.testimonial-position {
    display: block;
}
.testimonial-text p:last-child {
    margin: 0;
}
/*========================
	11. COUNTERUP
=========================*/
.counter {
    position: relative;
}
.counter-style-1 .counter{
	font-size:70px;
	font-weight:700;
	line-height: 70px;
	display: inline-block;
}
.counter-style-1 .counter-text{
	font-size:16px;	
	font-weight: 500;
}
.counter-style-1.text-black .counter-text{
	color:#000;
}
.counter-style-1 .icon{
	font-size:45px;
	margin-right:10px;
}
.counter-style-2{
	text-align:center;
	z-index:1;
	position:relative;
}
.counter-style-2 .counter-bx{
	margin-bottom:10px;
}
.counter-style-2 .counter-bx span{
	font-size: 110px;
	font-weight: 300;
	line-height: 110px;
	display: inline-block;
	color: var(--primary);
}
.counter-style-2 .counter-text{
	font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #627696;
    text-transform: uppercase;
}
.counter-style-2 .bg-icon{
	color:#000;
	width: 100%;
	text-align: center;
	top: -40px;
	bottom: auto;
}
.text-white .counter-style-2 .counter-text,
.text-white .counter-style-2 .counter-bx span{
	color:#fff;
}
.counter-style-3{
    text-align: center;
    padding: 30px 10px;
    border-radius: 8px;
}
.counter-style-3 .counter-text{
    font-size: 14px;
    color: #fff;
}
.counter-style-3 div span{
    font-size: 50px;
    color: #fff;
    line-height: 60px;
    margin-bottom: 5px;
    display: inline-block;
}

/*========================
	12. FOOTER
=========================*/
footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
    color: #b0b0b0;
}
footer p,
footer strong,
footer b,
footer {
    color: rgba(255,255,255,0.8);
}
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: rgba(255,255,255,0.8);
}

footer .btn-link,
footer a,
footer p a {
    color: rgba(255,255,255,0.6);
}
footer a:active,
footer a:focus,
footer a:hover {
    color: rgba(255,255,255,0.6);
}
/* widget color */
footer .widget_categories ul li a,
footer .widget_archive ul li a,
footer .widget_meta ul li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_nav_menu li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
    color: rgba(255,255,255,0.6);
}
footer.text-white .widget_categories ul li a, 
footer.text-white .widget_archive ul li a, 
footer.text-white .widget_meta ul li a, 
footer.text-white .widget_pages ul li a, 
footer.text-white .widget_recent_comments ul li a, 
footer.text-white .widget_nav_menu li a, 
footer.text-white .widget_recent_entries ul li a, 
footer.text-white .widget_services ul li a, 
footer.text-white a, 
footer.text-white .footer-bottom, 
footer.text-white p, 
footer.text-white strong, 
footer.text-white b, 
footer.text-white .widget .post-title, 
footer.text-white .widget-about .ttr-title, 
footer.text-white {
    color: rgba(255,255,255,1);
}
footer p {
    margin-bottom: 10px;
}
footer p,
footer li {
    font-size: 16px;
    line-height: 32px;
}
footer#footer {
    background-position: center;
    background-size: cover;
}
footer p {
    line-height: 32px;
    margin-bottom: 15px;
	color: rgba(255,255,255,0.6);
	font-weight: 400;
}
footer .footer_widget ul {
    list-style: none;
    margin-top: -10px;
	padding: 0;
}
/*widget li in footer*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
    border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}
footer .widget_services ul li {
	transition:all 1s;
	-ms-transition:all 1s;
	-o-transition:all 1s;
	-moz-transition:all 1s;
	-webkit-transition:all 1s;
	padding: 10px 0px 10px 15px;
}
footer .widget_services ul li:hover {
	transform: translateX(10px);
	-moz-transform: translateX(10px);
	-webkit-transform: translateX(10px);
	-o-transform: translateX(10px);
	-ms-transform: translateX(10px);
}
.footer-logo {
    width: 90px;
    display: block;
}

.footer-top .widget {
    margin-bottom: 30px;
}
.footer-bottom {
    background-color:#303030;
    padding: 25px 0;
    color: #b0b0b0;
	border-top:1px solid rgba(255,255,255,0.05);
}
.footer-bottom ul {
    margin: 0;
}
.footer-title{
	margin-bottom: 20px;
	color: #fff;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 20px;
	position: relative;
}
.footer_widget ul li a{
	padding: 5px 0;
    display: block;
    font-weight: 400;
    line-height: 28px;
}
.ft-social-bx{
	margin-bottom:0;
}
.ft-social-bx a{
	margin-right: 0;
	color: #fff;
	width: 45px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	text-align: center;
	font-size: 14px;
}
.ft-social-bx a i{
	margin:0;
}
.list-2 ul{
	margin:0;
	padding:0;
	list-style:none;
	display:table;
	margin-top:-8px;
	margin-bottom:-8px;
}
.list-2 ul li{
	width:50%;
	float:left;
}
.list-2 ul li a{
    font-size: 16px;
    font-weight: 400;
    padding: 8px 0;
    display: block;
	line-height: 22px;
}
.footer_talkus p{
	margin-top: -10px;
}
.footer-innner-btm{
	display:flex;
	justify-content: space-between;
	border-top:1px solid rgba(255,255,255,0.1);
	padding: 25px 0;
}
.footer-innner-btm p{
	color:#fff;
	opacity:0.5;
	font-weight:400;
	font-size:14px;
	margin:0;
}
.footer-style2 .footer-top{
	background: #232323;
	font-weight: 400;
}
.footer-style2 .footer-bottom{
	background: #232323;
	font-size: 14px;
	color:rgba(255,255,255,0.6);
	font-weight: 400;
}
.footer-style2 .footer-title{
	font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.footer-style2 .footer_widget ul li a{
    font-weight: 400;
}
.footer-style2 .footer-bottom p{
	font-size: 14px;
    color: rgba(255,255,255,0.6);
    font-weight: 400;
	line-height:24px;
}
.footer-info-bx .ttr-tilte{
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
}
.footer-info{
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding-bottom: 40px;
    margin-top: -35px;
}
.btn-long{
	padding-left:40px;
	padding-right:40px;
}
.footer-info-bx p{
	font-size: 14px;
    line-height: 22px;
}
.link-inline{
	margin:0;
	padding:0;
	list-style:none;
}
.link-inline li{
	display:inline-block;
    line-height: 24px;	
}
.link-inline li a{
    color: rgba(255,255,255,0.6);
    font-weight: 400;
    padding-left: 23px;
	font-size: 14px;	
}
.footer-style2 .ft-social-bx a{
    border-radius: 40px;
    font-size: 16px;
    height: 40px;
    width: 40px;
    line-height: 40px;
}
.widget_info ul{
	margin:0;
	padding:0;
	list-style:none;
    margin-top: -10px;	
}
.widget_info ul li{
    color: rgba(255,255,255,0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 0;	
	margin: 0 3px;
}
.widget_info ul li strong{
	font-weight: 300;
    margin-right: 5px;
	color:rgba(255,255,255,0.6);
}

/* Subscribe Form */
.subscribe-form input{
	background-color: #fff;
    height: 50px;
    padding: 10px 25px;
    margin-right: 0;
    color: #495057;
    font-size: 15px;
    border-radius: 0 !important;
    border: 0;
}
.subscribe-form .btn{
	padding: 0 30px;
    border-radius: 0 !important;
	margin-left:10px;
}
/* subscribe box 2 */

.subscribe-box .subscribe-title{
	max-width: 300px;
	width: 300px;
}
.subscribe-box .subscribe-title h4{ 
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 0;
}
.subscribe-box .form-control {
	background-color:rgba(0,0,0,0);
	color:#fff;
	border-bottom:1px solid rgba(255,255,255,0.3);
	padding-left:0;
}
.subscribe-box .form-control::-moz-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control:-moz-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control:-ms-input-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .form-control::-webkit-input-placeholder {
    color: #fff;
	opacity:0.5;
}
.subscribe-box .subscribe-form {
	width:100%;
	padding-left: 30px;
}
.subscribe-box{
	
}
.subscribe-box .btn{
	
}
.subscribe-action{
    max-width: 600px;
    margin: auto;
    padding: 10px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    border-radius: 60px;
	background: #fff;
}
.subscribe-action .form-control{
	border:0;
	padding:10px 30px;
	font-size:15px;
}
.bg-dark-gray{
	background-color:#3744a8;
}

/* Footer White */
.footer-white .footer-top.bt0{
	border-top:0;
}
.footer-white .footer-top,
.footer-white .footer-bottom {
	 background: #f2f2f2;
	 color:#272d3a;
	 border-top: 1px solid rgba(0,0,0,0.1);
}
.footer-white .footer-title{
	color:#000;
}
.footer-white .footer-title::after {
	width: 50px;
    background: var(--primary);
    opacity: 1;
    height: 2px;
}
.footer-white .footer-info-bx .ttr-tilte{
	color:#000;
}
.footer-white .footer-info{
	border-bottom:1px solid rgba(0,0,0,0.1);
}
footer.footer-white.footer-style2 .footer-bottom p, 
footer.footer-white .btn-link, 
footer.footer-white a, 
footer.footer-white p a,
footer.footer-white p, 
footer.footer-white strong, 
footer.footer-white b, 
footer.footer-white{
	color:#272d3a;
}
footer.footer-white .btn {
	color:#fff;
}
.pt-exebar {
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.pt-social-link,
.pt-btn-join{
	border-left: 1px solid rgba(0,0,0,0.1);
}
/*==== LOADING ====*/
#loading-icon-bx {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 999999999;
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: center;
}
/*========================
	13. INNER CONTENT CSS
=========================*/
/* About Section */
.service-info-bx{
	margin-top: -215px;
}
.service-bx{
	box-shadow:0 0 25px 0 rgba(29,25,0,0.25);
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	position:relative;
	background-color:#fff;
}
.service-bx [class*="feature-"]{
	box-shadow: 0 0 25px 0 rgba(29,25,0,0.15);
	margin-top: -30px;
	position: relative;
	top: -40px;
	margin-bottom: -20px;
}
.service-bx .info-bx{
	padding:30px;
}
.service-bx:hover{
	transform:translateY(-15px);
	-moz-transform:translateY(-15px);
	-webkit-transform:translateY(-15px);
	-ms-transform:translateY(-15px);
	-o-transform:translateY(-15px);
}
.service-icon-bx{
	display: flex;
    align-items: center;
    max-width: 700px;
    margin: auto;
    padding: 50px 0;
    border-bottom: 2px dashed rgba(0,0,0,0.2);
	position:relative;
	z-index: 1;
}
.service-icon-bx .service-icon a{
	font-size: 150px;
    background: #e1e3ff;
    border-radius: 100%;
    height: 300px;
    width: 300px;
    display: inline-block;
    text-align: center;
    line-height: 300px;
	overflow:hidden;
}
.service-icon-bx .service-icon a img{
	height: 100%;
	vertical-align: top;
	object-fit: cover;
}
.service-icon-bx .service-info{
	padding: 0 0 0 50px;
	max-width: 400px;
    margin-left: auto;
}
.service-icon-bx:nth-child(2n){
	flex-direction: row-reverse;
}
.service-icon-bx:nth-child(2n) .service-info{
	padding: 0 50px 0 0;
}
.service-icon-bx:after{
    content: "";
    width: 110px;
    height: calc(100% + 4px);
    position: absolute;
    bottom: -2px;
    border-radius: 120px 0 0px 120px;
    border: 2px dashed rgba(0,0,0,0.2);
    left: -110px;
    border-width: 2px 0 2px 2px;
    z-index: -1;
}
.service-icon-bx:first-child:after{
	height: 220px;
    left: -110px;
    border-radius: 110px 0 0 110px;
    border-width: 2px 0px 2px 2px;
    right: auto;
}
.service-icon-bx:nth-child(2n):after{
	height: calc(100% + 4px);
    left: auto;
    border-radius: 0 110px 110px 0;
    border-width: 2px 2px 2px 0px;
    right: -110px;
}
.service-icon-bx:last-child:after{
    left: auto;
    right: -110px;
    bottom: auto;
    top: -2px;
    border-radius: 0 120px 120px 0;
    border-width: 2px 2px 2px 0px;
	height: 220px;	
}
.service-icon-bx:nth-child(2n) + .service-icon-bx:last-child:after{
	right: auto;
    left: -110px;
    bottom: auto;
    top: -2px;
    border-radius: 120px 0 0 120px;
    border-width: 2px 0px 2px 2px;
}
.service-icon-bx .service-icon{
	position:relative;
}
.service-icon-bx .service-icon:after{
	content: attr(data-name);
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    line-height: 50px;
    top: 50%;
    right: -30px;
    width: 60px;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    height: 60px;
    background: var(--primary);
    border-radius: 100%;
    text-align: center;
    color: #fff;
    border: 5px solid #fff;
}
.service-icon-bx:nth-child(2n) .service-icon:after{
	right: auto;
	left:-30px;
}
.service-icon-bx:first-child{
	padding-top: 40px;
}
.service-icon-bx:last-child{
	border: 0;
    padding-bottom: 10px;
}
.service-list{
	margin:0;
	padding:0;
	list-style:none;
	border: 1px solid rgba(0,0,0,0.15);
    background-color: white;
    border-radius: 4px;
}
.service-list li a{
	background: #fff;
    display: block;
    padding: 12px 50px 12px 20px;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    color: #232323;
    font-weight: 500;
    font-size: 16px;
}
.service-list li:last-child a{
	border-bottom:0;
}
.service-list li.active a{
    background-color: var(--primary);
    color: #fff;
}
.service-list li a i{
	width: 26px;
    height: 26px;
    background: #eee;
    color: #000;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    border-radius: 35px;
    position: absolute;
    right: 20px;
    top: 10px;
    padding-left: 2px;
}
.service-list li a:after{
	content: "";
    height: 100%;
    background-color: var(--primary);
    width: 0;
    position: absolute;
    right: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    z-index: -1;
    top: 0;
    transform-origin: right;
    -moz-transform-origin: right;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
}
.service-list li:hover a{
	color: #fff;
}
.service-list li:hover a:after{
	 transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    width: 100%;
    right: auto;
    left: 0;
}
.service-list.style-2{
	background: transparent;
    border: 0;
}
.service-list.style-2 li a{
	border: 0;
    background: transparent;
    color: #555;
    font-size: 18px;
    font-weight: 500;
	padding: 7px 0 7px 40px;
}
.service-list.style-2 li a i{
    left: 0;
    width: 24px;
    height: auto;
    background: transparent;
    font-size: 24px;
    top: 7px;
    color: #555;
    padding: 0;
}
.service-list.style-2 li.active a i,
.service-list.style-2 li.active a,
.service-list.style-2 li:hover a i,
.service-list.style-2 li:hover a{
	color:var(--primary);
}
.service-list.style-2 li a:after{
	content: "\f30b";
    font-family: 'Line Awesome Free';
    width: auto;
    height: auto;
    background: transparent;
    color: var(--primary);
    font-weight: 900;
    font-size: 24px;
    right: 50%;
    opacity: 0;
}
.service-list.style-2 li.active a:after,
.service-list.style-2 li:hover a:after{
	right: 30px;
	opacity:1;
	    left: auto;
}
/* service-box */
.service-gallery-top .service-info .service-content,
.service-thumb:after,
.service-thumb,
.service-box .service-inner:after,
.service-box{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.service-box{
	box-shadow: 0 10px 30px 0 rgba(0,0,0,0.15);
	border-bottom: 3px solid var(--primary);
}
.service-box:hover{
	transform:translateY(-10px);
	-moz-transform:translateY(-10px);
	-ms-transform:translateY(-10px);
	-webkit-transform:translateY(-10px);
	-ms-transform:translateY(-10px);
}
.service-box .service-media{
	position:relative;
}
.service-box .service-info{
	padding: 30px;
}
.service-box .service-info .title{
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}
.service-box .service-info p{
	margin-bottom: 0;
	line-height: 24px;
}
.service-box .service-inner{
	position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
	z-index: 1;
}
.service-box .service-inner:after{
	background: var(--primary);
	position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
	content:"";
	opacity:0.9;
	z-index: -1;
	opacity:0;
}
.service-box:hover .service-inner:after{
	height:100%;
	opacity:0.9;
}
.service-box .service-inner .btn{
	transform: scaleY(0);
	-moz-transform: scaleY(0);
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
    -moz-transform-origin: bottom;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
	transition:all 0.4s ease 0.2s;
	-moz-transition:all 0.4s ease 0.2s;
	-ms-transition:all 0.4s ease 0.2s;
	-webkit-transition:all 0.4s ease 0.2s;
	-ms-transition:all 0.4s ease 0.2s;
	opacity:0;
}
.service-box:hover .service-inner .btn{
	transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	-webkit-transform: scaleY(1);
	opacity:1;
}
.service-gallery-top .service-info{
    height: 650px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
}
.service-gallery-top .service-info .container{
	position:relative;
	z-index:1;
}
.service-gallery-top .service-info .service-content .ttr-tilte{
	margin-bottom:10px;
}
.service-gallery-top .service-info .service-content{
	background: #fff;
    padding: 30px 40px;
    width: 450px;
    margin-bottom: 150px;
    position: relative;
    z-index: 1;
	opacity:0;
}
.service-gallery-top .slick-active .service-info .service-content{
	opacity:1;
}
.service-gallery-top .service-info .service-content p{
	font-size:18px;
	color:#585858;
}
.service-gallery-top .service-info .service-content .bg-icon{
	color: #000;
    line-height: 200px;
	right:20px;
}
.service-gallery-top .service-info:after{
	position:absolute;
	content:"";
	height:100%;
	width:100%;
	top:0;
	left:0;
	background:#000;
	opacity:0.3;
}
.swiper-service{
	position:relative;
}
.swiper-thumbs-box{
	z-index: 2;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background: rgba(0,0,0,0.2);
}
.service-thumb{
	padding:25px 5px;
	position: relative;
    z-index: 1;
	
}
.service-thumb:after{
	content: "";
    width: 15px;
    height: 15px;
    background: #000;
    position: absolute;
    transform: rotate(45deg) translateX(-50%);
    bottom: -10px;
    left: 50%;
	opacity:0;
}
.service-thumb .title{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}
.service-thumb .title i{
	font-size: 30px;
    line-height: 30px;
    margin-right: 10px;
}
.swiper-slide-thumb-active .service-thumb{
	background: var(--primary);
}
.left-border-1{
	padding-left: 30px;
    border-left: 1px solid #c2c2c2;
}
.brochure-bx{
    padding: 20px 20px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border: 1px solid rgba(0,0,0,0.15);
}
.brochure-bx .title-head {
	border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-bottom: 8px;
    position: relative;
    margin-bottom: 30px;	
}
.brochure-bx .title-head:after {
	background:var(--primary);
	content:"";
	width:30px;
	height:3px;
	position:absolute;
	bottom:-2px;
	left:0;
}
.brochure-bx:after{
	z-index: -1;
    content: "\f100";
    font-family: "Flaticon";
    position: absolute;
    color: #fff;
    font-size: 200px;
    bottom: 0;
    line-height: 200px;
    opacity: 0.1;
}
.download-link{
    position: relative;
    display: block;
    padding-left: 45px;	
    margin-top: 20px;	
}
.download-link .title{
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 22px;
}
.download-link span{
	color: #888;
    font-size: 14px;
    line-height: 18px;	
}
.download-link img{
    width: 45px;
    position: absolute;
    left: -5px;
    top: 0;
}
.testimonial-carousel .owl-stage{
	padding-left: 0px !important;
}
.testimonial-carousel .owl-nav{
	position: absolute;
    top: -80px;
    left: 0;
	text-align: right;
	max-width: 1140px;
	    width: 100%;
}
.owl-nav.disabled{
	display:none;
}
.help-bx{
	background:var(--primary)
}
.help-bx .info{
    padding: 15px 25px 35px 25px;
    text-align: center;	
	position:relative;
	z-index:1;
}
.help-bx .title{
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
}
.help-bx p{
	font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}
.help-bx .info img{
    max-width: 60px;
    margin-bottom: 20px;
}
.help-bx .info:before,
.help-bx .info:after{
	content: "";
    background: var(--primary);
    position: absolute;
    top: -25px;
    width: 100%;
    height: 50px;
    left: 0;
    transform: skewY(-8deg);
	z-index:-1;
}
.help-bx .info:before{
	background: #fff;
    top: -32px;
    transform: skewY(-5deg);
    height: 40px;
}










.testimonial-carousel.owl-btn-1 .owl-prev, 
.testimonial-carousel.owl-btn-1 .owl-next{
	line-height:50px;
	height:50px;
	width:50px;
	border-radius:50px;
	background-color:var(--sc-primary);
	color:#000;
	margin: 0 5px !important;
}
.testimonial-carousel .owl-nav .owl-next:hover,
.testimonial-carousel .owl-nav .owl-prev:hover{
	background-color:var(--primary);
	color:#fff;
}
.testimonial-carousel-2 .owl-dots{
	text-align:center;
	margin-top:15px;
}
.testimonial-carousel-2 .owl-dots .owl-dot{
	width: 15px;
	height: 4px;
	background: #000;
	display: inline-block;
	margin: 0 4px;
	cursor:pointer;
	border-radius:2px;
	opacity: 0.2;
}
.testimonial-carousel-2 .owl-dots .owl-dot.active{
	opacity:1;
	background:var(--primary)
}
/* heading-bx */
.heading-bx.left .title-head{
	margin-bottom: 10px;
    margin-top: 0;
    line-height: 32px;
    padding-left: 10px;
    border-left: 5px solid var(--primary);
}
.heading-bx.left p{
	max-width:500px;
	margin-bottom:0;
}
.title-head span{
	font-weight:400;
}
.heading-bx.style1 .title-head{
	color:#3f3e85;
	font-size:40px;
	line-height:50px;
	font-weight: 700;
	margin-bottom:0;
}
.heading-bx.style1 p{
	color:#6c7079;
	margin-bottom: 0;
}
.heading-bx.style1.text-white .title-head{
	color:#fff;
}
.heading-bx.style1.text-white p{
	color:#fff;
	opacity:0.7;
}

/* section space */
.section-sp1{
	padding-top:100px;
	padding-bottom:70px;
}
.section-sp2{
	padding-top:100px;
	padding-bottom:100px;
}
.section-sp3{
	padding-top:100px;
	padding-bottom:0;
}
.section-sp4{
	padding-top:50px;
	padding-bottom:50px;
}
/* Event Form */
.event-form{
	background:var(--primary);
	padding: 30px 30px;
	border-radius: 4px;	
}
.event-form.contact-bx textarea.form-control{
	height: 150px;
}
.event-form .heading-bx {
    margin-bottom: 20px;
}
.event-form .btn-secondry:active, 
.event-form .btn-secondry:hover, 
.event-form .btn-secondry:focus{
	background-color:#181b4a;
}


/* Time Line */
.timeline-nav{
	top: 150px;
}
.timeline-nav ul{
	margin: 0;
	padding: 0 30px 0 0;
	list-style: none;
	text-align:right;
}
.timeline-nav ul li{
	display:block;
	width: 100%;
}
.timeline-nav ul li a{
	display: block;
	width: 100%;
	font-size: 22px;
	font-weight: 700;
	color: var(--primary);
	opacity: 0.3;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	padding: 0 0;
	text-decoration:underline;
	padding: 5px 0;
}
.timeline-nav ul li a.active{
	opacity: 1;
	font-size: 44px;
	color: var(--primary);
	padding: 10px 0;
}
.timeline-box{
	margin-bottom:60px;
}
.timeline-box p{
    font-size: 18px;
    line-height: 32px;
    color: #404153;
}

/* Time Line 2 */
.timeline-view {
	position:relative;
    max-width: 1000px;
    margin: auto;	
}
.timeline-view:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25%;
    margin-left: 0px;
    border-left: 2px dashed #ccc;
}
.timeline-area {
	position: relative;
	background-color: inherit;
	width: 100%;
	left: 0; 
	display: flex; 
}
.timeline-area:last-child .timeline-content{
	border:0;
	margin-bottom:0;

}
.timeline-content{
    max-width: 75%;
    flex: 0 0 75%;
    padding-left: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 30px;
    margin-bottom: 50px;
}
.timeline-content p{
    font-size: 18px;
    color: #777;
    margin-bottom: 15px;
}
.timeline-date .date-bx{
    font-weight: 600;
    color: #000;
    font-size: 40px;
    line-height: 30px;
    padding: 10px 0 20px 0;
    border-radius: 4px;
    position: relative;
    width: 100%;
}
.timeline-date .date-bx:before {
	content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: -35px;
    background-color: white;
    border: 5px solid var(--primary);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}
/* .timeline-date .date-bx:after{
	content: "";
    width: 10px;
    height: 10px;
    background: var(--primary);
    position: absolute;
    right: -4px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 18px;
} */
.timeline-date{
    max-width: 25%;
    flex: 0 0 25%;
    padding-bottom: 80px;
    padding: 0 25px 50px 0;
}
.timeline-date h6{
	font-size: 16px;
    color: #777;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 0px;		
}
.timeline-date h4{
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 5px;
}
.timeline-date p{
    line-height: 24px;
    font-size: 16px;
    color: #888;
}














@media screen and (max-width: 600px) {
	.timeline-view:after {
	  left: 31px;
	}
	.timeline-area {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	    flex-direction: column;	
	}
	.timeline-area:before {
		left: 60px;
		border: medium solid white;
		border-width: 10px 10px 10px 0;
		border-color: transparent white transparent transparent;
	}
	.timeline-view .left:after, 
	.timeline-view .right:after {
		left: 15px;
	}
	.timeline-view .right {
		left: 0%;
	}
	.timeline-date .date-bx:before,
	.timeline-view:after{
		content:none;
	}
	.timeline-content{
		padding-bottom: 10px;
		margin-bottom: 30px;
	}
	.timeline-date,
	.timeline-content{
	    padding-left: 0;	
		max-width: 100%;
		flex: 0 0 100%;
	}
	.timeline-date{
		padding: 0;	
	}
}
/* award */
.award-bx{
	padding:30px;
	background:#fff;
	box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
	position:relative;
}
.award-bx .award-media{
    border: 2px dashed rgba(0,0,0,0.2);
    padding: 5px;
	position:relative;
}
.award-bx .award-media:before,
.award-bx .award-media:after{
	content:"";
	position:absolute;
	transition:all 0.5s;
	width:0;
	height:0;
	opacity:0;
}
.award-bx .award-media:before{
	top:-2px;
	left:-2px;
	border-left:4px solid var(--primary);
	border-top:4px solid var(--primary);
}
.award-bx .award-media:after{
	bottom:-2px;
	right:-2px;
	border-right:4px solid var(--primary);
	border-bottom:4px solid var(--primary);
}
.award-bx:hover .award-media:before,
.award-bx:hover .award-media:after{
	width:50%;
	height:50%;
	opacity:1;
}
.award-bx .award-info{
	text-align:center;
	padding-top:20px;
	
}
.award-bx .award-info .title{
    font-size: 24px;
    font-weight: 600;
    MARGIN-BOTTOM: 10PX;
}
.award-bx .award-info p{
    font-size: 18px;
    font-weight: 400;
    color: #777;
    margin-bottom: 0;
}
.bx-hover-up{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.bx-hover-up:hover{
	transform:translateY(-15px);
}
.award-bx.left{
	display: flex;
	align-items: flex-start;
}
.award-bx.left .award-media {
    min-width: 30%;
}
.award-bx.left .award-info {
    text-align: left;
    margin-left: 20px;
    padding-top: 0;
}
/* Job Career */
.job-career-box {
    padding: 30px;
    background: #fff;
    box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
}
.job-career-box p{
	font-size:18px;
	color:#666;
	margin-bottom:10px;
}
.job-career-box ul li{
	font-size:18px;
	color:#666;
}
.job-career-box .list-check li:before{
	top: 8px;
}
.job-career-box p strong{
	font-weight:600;
	color:#111;
}

/* Testimonials */
.testimonial-bx{
	background-color: #fff;
	padding:30px;
	border-radius: 4px;
	position:relative;
	overflow:hidden;
}
.testimonial-bx.shadow{
	box-shadow: 0px 30px 50px 0px rgba(0,0,0,0.1);
}
.testimonial-bx .testimonial-content p,
.testimonial-bx .testimonial-info p{
	margin: 0;
}
.testimonial-bx .testimonial-info h5{
	font-weight: 600;
	margin-bottom: 0;
	color: var(--primary);
}
.testimonial-bx .testimonial-info {
	margin-top: 15px;
}
.testimonial-bx .testimonial-info:after {
    content: "\f10e";
    position: absolute;
    font-family: fontawesome;
    font-size: 60px;
    color:var(--primary);
    right: 25px;
    bottom: 0;
	z-index: 9;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.testimonial-thumb {
    width: 80px;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 0 0 8px 5px rgba(0,0,0,0.1);
    position: absolute;
    left: 0;
	z-index: 9;
}
.testimonial-bx:hover .testimonial-info:after {
	opacity:0;
}
.testimonial-bx .testimonial-content p{
	color:#303030;
	font-size:16px;
}

.testimonial-bx .testimonial-pic {
	background: #FFF;
	width: 80px;
	height: 80px;
	position: absolute;
	right: -100px;
	border-radius: 100%;
	overflow: hidden;
	bottom: -100px;
	z-index: 10;
	border: 3px solid #fff;
	box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.15);
	transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	display: block;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.testimonial-bx:hover .testimonial-pic {
    right: 20px;
    bottom: 20px;
	transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}
.case-studies,
.our-partner,
.team-area,
.news-area{
	position:relative;
	z-index:2;
}
.our-partner{
	z-index:3;
}
.news-area{
	overflow:hidden;
}
.news-area:before {
    content: attr(data-name);
    font-size: 160px;
    line-height: 160px;
    left: 0;
    letter-spacing: 10px;
    position: absolute;
    top: 60px;
    z-index: -1;
    font-weight: 700;
	text-shadow:0 5px 40px #edf4ff;	
    color: #eef2f8;
	-webkit-animation:15s scrollup infinite linear;
	-moz-animation:15s scrollup infinite linear;
	-o-animation:15s scrollup infinite linear;
	-ms-animation:15s scrollup infinite linear;
	animation:15s scrollup infinite linear;
	white-space: nowrap;
}
.case-studies:before,
.our-partner:before,
.team-area:before {
	content: attr(data-name);
	font-size: 220px;
	line-height: 210px;
	left: 0;
	letter-spacing: 10px;
	position: absolute;
	bottom: -28px;
	z-index: -1;
	font-weight: 700;
	text-shadow: 0 5px 40px #edf4ff;
	color: #fff;
	width: 100%;
	text-align: center;
}

.our-partner:before{
	text-align:left;
	bottom:auto;
	top:0;
}
.case-studies:before{
	text-align:center;
	bottom:auto;
	top:-20px;
}
/* Image Move Css */
/* @-webkit-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-moz-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-o-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
}
@-ms-keyframes scrollup{
	100%{
		background-position: -3000px 0px;
	}
} */
@keyframes scrollup{
	100%{
		transform:translateX(-3000px);
	}
}


/* testimonial-bx style1 */
.testimonial-bx.style1{
	background: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    overflow: unset;
    max-width: 450px;
}
.testimonial-bx.style1:after{
    content: "";
    background: #fff;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 60px;
    bottom: -8px;
    transform: skew(20deg, -30deg);
}
.active .testimonial-bx.style1{
	opacity:0.3;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	-ms-transition:all 0.5s;
}
.center.active .testimonial-bx.style1{
	opacity:1;
}
.clinet-thumbs .testimonial-thumb {
	width: 80px;
    overflow: hidden;
    border: 0;
    box-shadow: none;
    position: unset;
    left: 0;
    z-index: 9;
    height: 80px;
    border-radius: 80px;
    opacity: 0.7;
	transform: scale(0.90);
	-moz-transform: scale(0.90);
	-webkit-transform: scale(0.90);
	-ms-transform: scale(0.90);
	-o-transform: scale(0.90);
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
	cursor:pointer;
}
.clinet-thumbs .swiper-slide{
    width: auto !important;
}
.clinet-thumbs .swiper-slide-thumb-active .testimonial-thumb {
	transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	opacity: 1;
}
.testimonial-bx.style1 .testimonial-content p{
	color:#424141;
	font-size:20px;
	line-height:30px;
}
.testimonial-bx.style1 .testimonial-info .name{
	font-size:24px;
	margin-bottom:5px;
	font-weight:500;
}
.testimonial-bx.style1 .testimonial-info p{
	color:var(--primary);
	font-weight:500;
}
.testimonial-bx.style1 .testimonial-info:after{
	content:none;
}
.testimonial-bx.style2{
	transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	outline: 0;
}
.testimonial-bx.style2.blue{
	background:#f5f5ff;
}
.testimonial-bx.style2.orange{
	background:#fff9f3;
}
.testimonial-bx.style2.red{
	background:#fff3f3;
}
.testimonial-bx.style2.red .testimonial-info:after{
    background: #fc6765;
    box-shadow: 10px 10px 50px 0px #fc6765;
	color: #fff;
}
.testimonial-bx.style2.orange .testimonial-info:after{
    background: #ffa143;
    box-shadow: 10px 10px 50px 0px #ffa143;
	color: #fff;
}
.testimonial-bx.style2.blue .testimonial-info:after{
    background: #675fff;
    box-shadow: 10px 10px 50px 0px #675fff;
	color: #fff;
}
.testimonial-bx.style2:before,
.testimonial-bx.style2:after{
	content:none;
}
.testimonial-bx.style2 .title{
	margin-bottom:10px;
}
.testimonial-rating{
	color:#ffb944;
	margin-bottom: 20px;
}
.testimonial-bx.style2 .testimonial-thumb{
	position: absolute;
	width: 60px;
	border: 0;
	left:0;
    box-shadow: none;
	border-radius: 40px;
}
.testimonial-bx.style2 .client-info{
	position:relative;
	padding-left:80px;
	margin-top: 30px;
}
.testimonial-bx.style2 .client-info .name{
	font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
}
.testimonial-bx.style2 .testimonial-info{
	margin-top: 0;
	padding: 5px 0;
}
.testimonial-bx.style2 .testimonial-info p{
	font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 600;
    color: #6d747e;
}
.testimonial-bx.style2 .testimonial-info:after{
    font-size: 20px;
    right: 0;
    bottom: 0;
    background: #ffede4;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 50px;
}
.testimonial-bx.style2:hover .testimonial-info:after{
	opacity:1;
}
.testimonial-bx.style2:hover{
	transform:translateY(-10px);
}
.client-appoint-box .ajax-form{
	padding-left:15px;
	position:relative;
}
.client-appoint-box .appoint-bx1 .input-bx:after{
	background:var(--primary);
}
.apoint-btn-box{
	background: #03204a;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    position: absolute;
    left: -178px;
    top: 50%;
    transform: rotate(-90deg) translateX(0%);
    -moz-transform: rotate(-90deg) translateX(0%);
    -webkit-transform: rotate(-90deg) translateX(0%);
    -ms-transform: rotate(-90deg) translateX(0%);
    -o-transform: rotate(-90deg) translateX(0%);
    padding: 15px 30px;
}
.apoint-btn-box:before,
.apoint-btn-box:after{
	content: "";
    background: #03204a;
    position: absolute;
    height: 100%;
    width: 40%;
    transform: skewX(40deg);
    -moz-transform: skewX(40deg);
    -ms-transform: skewX(40deg);
    -webkit-transform: skewX(40deg);
    -o-transform: skewX(40deg);
    top: 0;
    right: -30px;
    z-index: -1;
}
.apoint-btn-box:before{
	right: auto;
    left: -30px;
	transform: skewX(-40deg);
    -moz-transform: skewX(-40deg);
    -ms-transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    -o-transform: skewX(-40deg);
}
.client-appoint-box .container,
.client-appoint-box {
	position:relative;
	z-index:1;
}
.client-appoint-box:after{
	content: "";
    z-index: -1;
    width: 50%;
    right: 0;
    background: #232323;
    height: 100%;
    top: 0;
    position: absolute;
}
/* testimonial-bx style1 END */
.ovbl-middle:after,
.ovbl-light:after,
.ovbl-dark:after{
	background: #000;
}
.our-story{
	position:relative;
	z-index: 1;
}
.our-story:after{
	width:50%;
	content:"";
	height:100%;
	position:absolute;
	left:50%;
	background-color:#fff;
	top:0;
	z-index: -1;
}
.our-story .style1 p{
	margin-bottom:20px;
}
.our-story .heading-bx.style1 .title-head{
	margin-bottom:10px;
}
.video-bx{
	position:relative;
}
.video-bx .video-media-bx a{
	width:80px;
	height:80px;
	border-radius:80px;
	line-height:80px;
	font-size:24px;
}
.counter-style-1 span{
	font-size:70px;
	font-weight:700;
	line-height: 70px;
	display: inline-block;
}
.counter-style-1 .counter-text{
	color: #fff;
	font-size: 18px;
	line-height: 40px;
}
.join-content-bx{
	max-width:800px;
	margin:auto;
}
.join-content-bx h2{
	font-size: 45px;
	line-height: 65px;
	margin: 0px 0 20px 0;
}
.join-content-bx h4{
	font-weight:400;
	font-size:30px;
}
.join-content-bx p{
	line-height:24px;
	opacity: 0.8;
}
.choose-bx .choose-bx-in{
	margin-top:-20px;
}

.mvimg{
	width: 50vw;
	max-width: 50vw;
	height: 100%;
	object-fit: cover;
}
/* MemberShip */
.pricingtable-inner {
	text-align: center;
	overflow: hidden;
	background:	#fff;
}
.pricingtable-features,
.pricingtable-features li,
.pricingtable-footer{
	border:0;
}
.pricingtable-features li{
	color: #000;
    font-size: 18px;
    font-weight: 400;
}
.pricingtable-bx {
    font-size: 70px;
    font-weight: 600;
    line-height: 70px;
    color: #000;
}
.priceing-doller {
	vertical-align: top;
    font-size: 24px;
    line-height: 24px;
    color: var(--primary);
    font-weight: 700;
    position: relative;
    top: 13px;
}
.pricingtable-features{
	text-align:left;
    padding: 20px 0;	
}
.pricingtable-type{
    color: #a3a3a3;
    font-weight: 600;
}
.pricingtable-type::before{
	content: "/";
	margin-right: 5px;
	color:#a3a3a3;
}
.pricingtable-price{
	padding: 30px 20px;
	background-color: transparent;
	border: 1px solid rgba(0,0,0,0.1);
	border-width:0 0 1px 0;
}
.pricingtable-title {
	padding:18px 10px 28px 10px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	position: relative;
}
.pricingtable-title h2{
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	color:#000;
}
.pricingtable-title p{
	font-size: 13px;
	line-height: 20px;
	font-weight: 600;
	display: inline-block;
	background: var(--primary);
	padding: 2px 15px;
	position: absolute;
	bottom: -13px;
	white-space: nowrap;
	left: 50%;
	transform: translateX(-50%);
}
.pricingtable-footer{
	border-top: 1px solid rgba(0,0,0,0.1);
}
.pricingtable-footer .btn{
	font-size:16px;
	padding: 10px 30px;
}
.pricingtable-features li{
	padding: 5px 40px;
}
.pricingtable-features li i{
	color:var(--primary);
}
/* Profile Box */
.profile-bx{
	border:1px solid #e9e9e9;
	position:sticky;
	top:100px;
}
.user-profile-thumb{
	width:100px;
	height:100px;
	overflow:hidden;
	border-radius:100px;
	border: 5px solid #fff;
	box-shadow:0 0 15px 0 rgba(0,0,0,0.2);
	margin:25px auto 15px; 
}
.profile-info{
	margin-bottom:15px;
}
.profile-info h4{
	font-size:18px;	
	margin-bottom: 0;
}
.profile-info span{
	font-size:14px;
	color:#717171;
}
.profile-social ul li a{
	width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    border: 1px solid #e9e9e9;
    line-height: 34px;
    border-radius: 40px;
    color: #000;
    font-size: 14px;
}
.profile-social{
	border-bottom: 1px solid #e9e9e9;
	padding-bottom:15px;
}
.profile-content-bx{
	border: 1px solid #e9e9e9;
}
.profile-tabnav{ 
	margin:20px 0;
}
.profile-tabnav .nav{ 
	border:0;
}
.profile-tabnav .nav li{  
	display:block;
	width:100%;
}
.profile-tabnav .nav li a{
	border:0;
	color: #8a8a8a;
	font-size: 15px;
	text-align: left;
	padding: 10px 30px;
}
.profile-tabnav .nav li a.active{
	background-color: var(--primary);
	color: #fff;
	border-radius: 0;
}
.profile-tabnav .nav li a i{
	margin-right:10px;
}
.profile-head h3{
	font-size:20px;
	text-transform:uppercase;
	margin-bottom:0;
	font-weight:500;
}
.profile-head{
	display:flex;
	padding: 10px 15px 10px 30px;
	border-bottom: 1px solid #e9e9e9;
	align-items: center;
}
.profile-head .feature-filters{
	margin-bottom:0;
}
.courses-filter .action-card{
	margin-bottom:30px;
}
.courses-filter{
	padding:30px 30px 0 30px;
}
.profile-head .feature-filters.style1 li a{
	font-weight: 400;
	font-size: 15px;
}
.edit-profile h3{
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}
.edit-profile .help{
	font-size:12px;
	line-height:18px;

	display: block;
}
.edit-profile .col-form-label{
	font-size:14px;
	font-weight: 400;
}
.edit-profile{
	padding:30px;
}
.edit-profile .form-group {
	margin-bottom: 15px;
}
/* My Account */
.account-form{
	display:flex;
	width: 100%;
	position: relative;
}
.account-head{
	position: sticky;
	left:0;
	top:0;
	z-index: 1;
	width: 500px;
	min-width: 500px;
	height: 100vh;
	background-position: center;
	text-align: center;
	align-items: center;
	display: flex;
	vertical-align: middle;
}
.account-head a{
	display:block;
	width:100%;
}
.account-head:after{
	opacity:0.9;
	content:"";
	position:absolute;
	left:0;
	top:0;
	z-index:-1;
	width:100%;
	height:100%;
	background: #ff5e14; /* Old browsers */
	background: -moz-linear-gradient(45deg, #ff5e14 0%, #ff8e14 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #ff5e14 0%,#ff8e14 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #ff5e14 0%,#ff8e14 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5e14', endColorstr='#ff8e14',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.account-form-inner {
    width: 100%;
	align-self: center;
}
.account-container {
    max-width: 400px;
	margin: auto;
	padding: 30px 0;	
}
.custom-control-label:before{
	width: 20px;
	height: 20px;
	background-color: transparent;
	border: 2px solid var(--primary);
	top: 2px;
}
.custom-control-label:after{
	width: 20px;
	height: 20px;
	top: 2px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
	background-color: var(--primary);
}
.account-container .form-forget{
	display:flex;
}
.account-container .form-forget .custom-control-label{
	font-weight:400;
}
.account-container .btn.button-md{
	padding: 12px 40px;
}
.account-container .form-control{
	border-color: rgba(0,0,0,0.2);
}
.account-container .facebook i,
.account-container .google-plus i{
	border-right:1px solid #fff;
	padding-right:10px;
	margin-right:10px;
}
.account-form .heading-bx p a{
	color: var(--primary);
	text-decoration: underline;
	padding-bottom: 0px;
}
/* .g-recaptcha {
  transform-origin: left top;
} */
/* Google Recaptcha */
 
.g-recaptcha,
#rc-imageselect {
    transform:scale(0.99);
    -webkit-transform:scale(0.99);
    -moz-transform:scale(0.99);
    -o-transform:scale(0.99);
    
    transform-origin:0 0;
    -o-transform-origin:0 0;
    -moz-transform-origin:0 0;
    -webkit-transform-origin:0 0;
}
@media screen and (max-width: 575px){
	#rc-imageselect, 
	.g-recaptcha {
        transform:scale(0.77);
        -moz-transform:scale(0.77);
        -o-transform:scale(0.77);
        -webkit-transform:scale(0.77);
        
        transform-origin:0 0;
        -moz-transform-origin:0 0;
        -o-transform-origin:0 0;
        -webkit-transform-origin:0 0;
    
    }
}
.recaptcha-box{
 height:60px;
}
.g-recaptcha > div{
	width:250px !important; 
}
/* Google Recaptcha */
/* error */
.error-page{
	text-align:center;
}
.error-404{
	height: 100vh;
}
.error-404 .account-container {
	max-width: 700px;
}
.error-title{
	font-size: 250px;
    line-height: 250px;
    margin-bottom: 0;
	font-weight: 700;
	color: #06002a;
	display: inline-flex;
}
.error-title span:last-child {
    transform: rotateX(180deg) translateY(10px);
    -moz-transform: rotateX(180deg) translateY(10px);
    -webkit-transform: rotateX(180deg) translateY(10px);
    -ms-transform: rotateX(180deg) translateY(10px);
    -o-transform: rotateX(180deg) translateY(10px);
}
.error-page h3{
	font-size: 80px;
	line-height: 80px;
	color: #716e84;
}
.error-page h5{
	font-size: 22px;
	line-height: 30px;
	color: #716e84;
    font-weight: 500;
	margin-bottom: 30px;
}
.error-page p{
	font-size:16px;
	line-height:26px;
}
.why-chooses-bx .faq-bx{
	max-width: 700px;
	margin: auto;
}
.why-chooses-bx .faq-bx .panel {
	background:#fff;
	margin-bottom: 6px;
}
/* Services Box */
.services-bx:hover:after{
	background: #0066ff; /* Old browsers */
	background: -moz-linear-gradient(top, #0066ff 0%, #6021ff 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #0066ff 0%,#6021ff 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #0066ff 0%,#6021ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0066ff', endColorstr='#6021ff',GradientType=0 ); /* IE6-9 */
}
.services-bx .ttr-tilte,
.service-no,
.services-bx p,
.services-bx,
.services-bx:after{
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.services-bx:after{
	content: "";
	position: absolute;
	height: 100%;
	bottom: -100%;
	width: 100%;
	left: 0;
	z-index: -1;
	border-radius:100%;
}
.services-bx:hover:after{
	border-radius:0;
	bottom: 0;
	border-radius:0;
}
.services-bx:hover{
	transform:translateY(-15px);
	-moz-transform:translateY(-15px);
	-o-transform:translateY(-15px);
	-webkit-transform:translateY(-15px);
	-ms-transform:translateY(-15px);
}
.service-no {
	line-height: 70px;
    font-size: 70px;
    color: transparent;
    position: absolute;
    top: 40px;
    right: 40px;
    font-weight: 500;
	background-clip: text;
	color:#393dff;
}
.services-bx{
	box-shadow: 0 10px 30px 0 rgba(8,0,255,0.1);
	padding: 50px;
	position: relative;
	z-index: 1;
	overflow:hidden;
	background: #fff;
}
.services-bx p{
	font-size:14px;
	line-height:28px;
	color:#69677b;
}
.services-bx .ttr-tilte{
	font-size:20px;
	color:#000;
}
.services-bx .feature-lg i{
	line-height:60px;
}
.services-bx .readmore{
	color:#000;
}
.services-bx .feature-lg .icon-cell{
	color:#000;
}
.services-bx .feature-lg{
	text-align:left;
}
.services-bx:hover .ttr-tilte{
	color:#fff;
}
.services-bx:hover p{
	color:#fff;
	opacity:0.8;
}
.services-bx:hover .feature-lg .icon-cell,
.services-bx:hover .readmore{
	color:#fff;
}
.services-bx:hover .service-no{
	color:#83ffbe;
}
/* Appointment */
.appointment-box .container{
	z-index: 2;
}
.appointment-box .appoint-bg{
	position: absolute;
	z-index: 1;
	width: 100%;
	bottom: 0;
}

.appointment-box .contact-bx .input-group label{
	color: #000;
}
.appoint-bx1 .form-control{
    background: transparent;
    border-width: 0 0 2px 0px;
    color: #fff;
    font-size: 16px;
    padding: 10px 0;
}
.appoint-bx1 .form-control::-moz-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control:-moz-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control:-ms-input-placeholder {
    color: #fff;
}
.appoint-bx1 .form-control::-webkit-input-placeholder {
    color: #fff;
}
.appoint-bx1 .input-bx{
	position: relative;
	z-index:1;
}
.appoint-bx1 .input-bx:after{
	content: "";
    width: 0;
    height: 2px;
    position: absolute;
    background: #232323;
    bottom: 0;
    right: 0;
    transform-origin: right;
	transition:all 0.3s;
	z-index:4;
}
.appoint-bx1 .focused .input-bx:after{
	right: auto;
	left:0;
	width:100%;
}
.appoint-video{
    border-radius: 8px 8px 60px 8px;
    overflow: hidden;
    margin-right: 30px;
	height:100%;
}
.appoint-video img{
	height:100%;
	object-fit:cover;
	width: 100%;	
}
/* contact-bx-area1 */
.contact-bx-area1{
	position: relative;
}
.contact-bx-area1:after {
    content: "";
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #eaf1f9;
    z-index: 0;
}
.contact-bx-area1 .contact-bx{
	z-index: 999;
	position: relative;
	padding: 0 30px;
}

/* contact-bx-area2 */
.contact-bx-area2{
    box-shadow: 0 10px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-top: -50px;
    z-index: 1;
    position: relative;
}
.contact-bx-area2 .right-info{
	padding:40px;
}
.contact-icon-box .title{
    position: relative;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    line-height: 28px;
}
.contact-icon-box {
    margin-bottom: 20px;
    border: 2px solid rgba(255,255,255,0.3);
    padding: 20px 20px;
    border-radius: 6px;
    background: #fff;
    position: relative;
	z-index: 1;
	overflow: hidden;	
}
.contact-icon-box .bg-icon{
	color: #000;
    font-size: 200px;
    line-height: 180px;
    bottom: -30px;
    right: -40px;
    opacity: 0.05;
}
.contact-icon-box .icon{
    width: 40px;
    height: 40px;
    background: var(--primary);
    border-radius: 6px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
}
.contact-icon-box a,
.contact-icon-box p{
	margin-bottom: 4px;
    color: #666;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

/* Agency Slider */
.slider1 .tparrows::before{
	font-size: 20px;
	line-height: 60px;
}
.slider1 .tparrows{
	width: 60px;
	height: 60px;
}
.slider1 .tp-rightarrow.tparrows{
	background:#17161f;
}
.slider1 .tp-leftarrow.tparrows{
	background:#fcf810;
}
.slider1 .tp-leftarrow.tparrows::before{
	color:#000;
}

.slider1 .tp-leftarrow.tparrows:hover{
	background:#fff;
}
.slider1 .tp-leftarrow.tparrows:hover:before{
	color:#000;
}
.slider1 .tp-rightarrow.tparrows:hover{
	background:#232eff;
}
.slider1 .tp-rightarrow.tparrows:hover:before{
	color:#fff;
}
.slideinfo{
	position:absolute;
	bottom:0;
	display: flex;
	align-items: center;
	transform: rotate(90deg) translateX(-100%);
	-o-transform: rotate(90deg) translateX(-100%);
	-webkit-transform: rotate(90deg) translateX(-100%);
	-moz-transform: rotate(90deg) translateX(-100%);
	-ms-transform: rotate(90deg) translateX(-100%);
	transform-origin: left bottom;
	-o-transform-origin: left bottom;
	-ms-transform-origin: left bottom;
	-webkit-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
}
.slide-social{
	list-style:none;
	margin:0;
	padding:0 30px 0 0;
	
}
.slide-social li{
	display: inline-block;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
}
.slide-social li:first-child{
	padding-right:50px;
	position:relative;
	margin-right: 30px;
}
.slide-social li:first-child:after{
	height:1px;
	width:40px;
	right:0;
	position:absolute;
	content:"";
	background:#fff;
	top: 10px;
}
.slide-social li a{
	color: #fff;
	padding: 0 5px;
	display:block;
	transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
}
.slider-contact.btn {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    background: #232eff;
    padding: 15px 40px;
	font-size:16px;
}
.contact-sidebar{
    position: fixed;
    right: -400px;
    width: 350px;
    height: 100vh;
    background: #fff;
    z-index: 100001;
    overflow-y: scroll;
	top: 0;
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    
}
.contact-sidebar .inner{
	padding: 40px;
}
.contact-sidebar .title{
	font-size:22px;
	line-height:32px;
	color:#000;
    margin-bottom: 20px;	
}
.contact-sidebar .logo{
	margin-bottom: 20px;
}
.contact-sidebar .logo img{
    height: 40px;
}
.contact-sidebar.open{
	right:0;
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
}
.contact-sidebtn.close{
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
	display:none;
}
.contact-sidebar.open + .contact-sidebtn.close{
    position: fixed;
    z-index: 100000;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    left: 0;
    top: 0;
    display:block;
	opacity:1;
	transition:all 0.2s;
	-moz-transition:all 0.2s;
	-webkit-transition:all 0.2s;
	-ms-transition:all 0.2s;
	-o-transition:all 0.2s;
}
.contact-sidebtn.close i{
    background: var(--primary);
    text-shadow: none;
    color: #fff;
    width: 50px;
    height: 50px;
    display: block;
    opacity: 1;
    line-height: 50px;
    font-size: 18px;
    text-align: center;
}
.contact-infolist {
    margin: 0 0 40px 0;
    padding: 25px 0 5px 0;
    list-style: none;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.contact-infolist li{
	position:relative;
    padding:0px 20px 20px 80px;	
	
}
.contact-infolist li .contact-title{
	color: #232323;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 2px;
}
.contact-infolist li img{
	position: absolute;
    left: 0;
    top: 10px;
    width: 60px;
}
.contact-infolist li p,
.contact-infolist li a{
    font-size: 16px;
    line-height: 24px;
    color: #555;
    font-weight: 400;
    margin-bottom: 5px;
}

.contact-sidebar .input-group label {
    font-size: 14px;
    font-weight: 400;
    color: #606060;
    position: absolute;
    top: 10px;
    left: 0;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
	
}
.contact-sidebar .input-group textarea.form-control {
	height:100px;
}
.contact-sidebar .input-group .form-control {
	border-width: 0 0 2px 0;
    background-color: rgba(0,0,0,0);
    padding: 10px 0;
    box-shadow: none;
    height: 45px;
    font-size: 14px;
    border-color: var(--primary);
    border-radius: 0;
}
.contact-sidebar .focused .input-group label {
    top: -10px;
    font-size: 11px;
    color: var(--primary);
    z-index: 2;
	font-weight: 600;
}
.contact-sidebar .g-recaptcha,
.contact-sidebar #rc-imageselect{
	transform: scale(0.7);
	-moz-transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-ms-transform: scale(0.7);
	-o-transform: scale(0.7);
}
/* Portfolio Box */
.gallery-top{
	margin-bottom:10px;
}
.protfolio-info {
	margin:0;
	padding:0;
	list-style:none;
	margin-bottom: 50px;
	border: 1px solid rgba(0,0,0,0.1);
}
.protfolio-info.m-b0{
	margin-bottom:0;
}
.protfolio-info li{
	display: flex;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.protfolio-info li:last-child{
	border-bottom:0;
}
.protfolio-info li:nth-child(2n){
	background:#f3f3f3;
}
.protfolio-info li strong,
.protfolio-info li span{
	flex:0 0 50%;
	max-width:50%;
	padding: 10px 15px;
}
.protfolio-info li strong{
	color:#000;
	font-weight: 600;
	font-size: 18px;
}
.protfolio-info li span{
	color:#333	
}
.content-info{
	padding: 30px;
	background:#232323;
	color:#fff;
}
.gallery-thumbs img{
	opacity:0.5;
}
.gallery-thumbs{
	margin: 8px -4px 0;
}
.gallery-thumbs li{
	border: 0;
    padding: 0 4px;
    width: 25%;
}
.gallery-thumbs .react-tabs__tab--selected img{
	opacity:1;
}
/* Feature Box */
.feature-bx1{
	padding: 50px 30px;
    border-radius: 0;
    background: #fff;
    margin-bottom: 30px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    position: relative;
    z-index: 1;
    text-align: center;
    box-shadow: 0px 20px 30px 0 rgba(0,0,0,0.1);
}
.feature-bx1.active,
.feature-bx1:hover{
	transform:scale(1.05);
}
.feature-container.feature-bx1 .ttr-tilte{
	margin-bottom:10px;
}
.feature-container.feature-bx1 p{
	font-size:18px;
	margin-bottom: 15px;
}
.feature-bx1 .btn-link{
	text-transform: uppercase;
	font-weight: 500;
	border-bottom: 2px solid;
	color: var(--primary);
}
.feature-bx1 .btn-link:hover{
	text-decoration:none;
}
.feature-bx1 .icon-cell i{
	font-size:80px;
}
.feature-bx1 .bg-img{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.feature-bx2 .feature-lg i,
.feature-bx1 .icon-cell{
	line-height: 80px;
}
.feature-bx1:hover,
.feature-bx1.active{
	box-shadow:0 10px 35px -15px var(--primary);
	border-color:#fff;
	background:var(--primary);
}
.feature-bx1.active .icon-cell,
.feature-bx1.active .btn-link,
.feature-bx1.active .ttr-tilte,
.feature-bx1.active p,
.feature-bx1:hover .icon-cell,
.feature-bx1:hover .btn-link,
.feature-bx1:hover .ttr-tilte,
.feature-bx1:hover p{
	color:#fff;
}
.feature-bx1.active .bg-img,
.feature-bx1:hover .bg-img{
	opacity:0.1;
}